import Radio from '@mui/material/Radio';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import * as styles from '../../assets/css/RequestForQuote/RequestForQuoteOnPremProductTypeOptions';
import type { RFQBundleType } from '../../constants/requestForQuote.constants';
import {
  AUTOMATION_SUITE,
  AUTOMATION_SUITE_BUNDLE_TYPE,
  CLOUD,
  CLOUD_BUNDLE_TYPE,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
  NewAutomationCloudBundleOption,
  NewAutomationSuiteBundleOption,
  ORCHESTRATOR_BUNDLE_TYPE,
  PROCESS_MINING_BUNDLE_TYPE,
} from '../../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../../interfaces/contextStore';
// Constants
import {
  getBundleDisplayName,
  getBundleName,
  getSubscriptionName,
} from '../../lib/licenses.utils';
// Global state
import { StoreContext } from '../../store';
import type { SkuType } from '../../types/hapo.types';
import Container from '../CustomerPortal-New-Container';
import ArrowDown from './../../assets/img/svg/arrows/Arrow_Down.svg';

const RequestForQuoteBundleOptions = (props: {
  newBundleRadioLabelText: string;
  addToExistingBundleText: string;
  noExistingBundlesText: string;
  selectBundlePromptText: string;
  bundleType: RFQBundleType;
  availableNewBundles: SkuType[];
  cartProducts: GlobalStoreState['rfqCart']['products'] | GlobalStoreState['hapoProducts'];
  lastSelectedOrchestrator: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'];
  existingBundles: any[];
  handleOnPremBundleChange: (dispatch: any, bundleType: string) => void;
  handleAddBundleToCart: (
    dispatch: any,
    skuName: string,
    skuId: string,
    productCode: string,
    bundleType: string
  ) => void;
  handleResetNewBundle: (dispatch: any, orchId?: string) => void;
  handleSelectExistingBundleDropdown: (
    dispatch: any,
    name: string,
    license: string,
    bundleType: string,
    displayName: string
  ) => void;
}) => {
  const { t } = useTranslation('common');
  const {
    newBundleRadioLabelText,
    noExistingBundlesText,
    addToExistingBundleText,
    selectBundlePromptText,
    lastSelectedOrchestrator,
    bundleType,
  } = props;
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const accountLicenses = props.existingBundles || [];
  const availableNewBundles = props.availableNewBundles || [];
  const {
    existingBundleBundleType,
    newBundleBundleType,
  } = getBundleTypeConstants(bundleType);

  // Since only one new bundle can be added at a time, hold reference
  const newBundleInCart = props.cartProducts.find(
    sku => sku.type === newBundleBundleType
  );

  // Set the lastSelectedOrchestrator variable
  let lastSelectedBundleType: string | undefined;
  if (lastSelectedOrchestrator?.type === newBundleBundleType) {
    lastSelectedBundleType = 'new-bundle';
  } else if (lastSelectedOrchestrator?.type === existingBundleBundleType) {
    lastSelectedBundleType = 'existing-bundle';
  }

  // The current selected option
  const [ onPremSelectedOption, setOnPremSelectedOption ] = useState(
    lastSelectedBundleType === undefined
      ? 'existing-bundle'
      : lastSelectedBundleType
  );
  // This must be for the Select on existing orch. If new orch is selected, this should not be active!!! - Alex
  const [
    onPremExistingOrchestratorOptions,
    setOnPremExistingOrchestratorOptions,
  ] = useState(onPremSelectedOption === 'existing-bundle');
  const [
    onPremNewOrchestratorOptions,
    setOnPremNewOrchestratorOptions,
  ] = useState(onPremSelectedOption === 'new-bundle');

  const [
    isOnPremExistingOrchestratorSelectActive,
    setIsOnPremExistingOrchestratorSelectActive,
  ] = useState(false);

  // Listen to these changes because user can click from cart panel
  useEffect(() => {
    if (lastSelectedBundleType) {
      setOnPremSelectedOption(lastSelectedBundleType);
      setOnPremExistingOrchestratorOptions(
        lastSelectedBundleType === 'existing-bundle'
      );
      setOnPremNewOrchestratorOptions(lastSelectedBundleType === 'new-bundle');
    }
  }, [ lastSelectedBundleType ]);

  /**
   * Returns the bundle nickname and license code if chosen
   */
  const getDefaultExistingOptionsDisplayNameCode = () => {
    let licenseName,
      licenseCode = '-----';
    if (
      lastSelectedOrchestrator?.existing_license_code &&
      ((bundleType === ORCHESTRATOR_BUNDLE_TYPE &&
        lastSelectedOrchestrator?.type === EXISTING_ORCHESTRATOR) ||
        (bundleType === PROCESS_MINING_BUNDLE_TYPE &&
          lastSelectedOrchestrator?.type === EXISTING_PROCESS_MINING) ||
        (bundleType === CLOUD_BUNDLE_TYPE &&
          lastSelectedOrchestrator?.type === EXISTING_CLOUD) ||
        (bundleType === AUTOMATION_SUITE_BUNDLE_TYPE &&
          lastSelectedOrchestrator?.type === EXISTING_AUTOMATION_SUITE))
    ) {
      licenseName =
        lastSelectedOrchestrator?.display_name ||
        lastSelectedOrchestrator?.license_name;
      licenseCode = lastSelectedOrchestrator?.existing_license_code;
    }

    return {
      defaultLicenseName: licenseName,
      defaultLicenseCode: licenseCode,
    };
  };

  const getExistingBundleList = () => {
    if (accountLicenses.length === 0) {
      return noExistingBundlesComp(noExistingBundlesText);
    }

    return accountLicenses.map((license: any, index: number) => (
      <div
        key={license.licenseCode || index}
        className="RequestForQuote__onPremExistingOrchestratorOptions__dropdownItem"
        onClick={(e: any) =>
          handleOrchestratorSelect(
            getBundleName(
              license.bundleCode,
              getSubscriptionName(license.subscriptionCode)
            ),
            license.licenseCode,
            getBundleDisplayName(
              license.licenseCode,
              state.companyLicenses?.licenses
            ),
            e
          )}
      >
        <div className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem">
          <p className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__title">
            {getBundleDisplayName(
              license.licenseCode,
              state.companyLicenses?.licenses
            ) ||
              getBundleName(
                license.bundleCode,
                getSubscriptionName(license.subscriptionCode)
              )}
          </p>
          <p className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__licenceCode">
            {license.licenseCode}
          </p>
        </div>
      </div>
    ));
  };

  const getNewBundlesList = () => availableNewBundles.map((sku: any) => {
    const isAvailableNewBundleInCart = newBundleInCart?.sku_name?.includes(
      sku.sku_name
    );
    return (
      <div
        key={sku._id}
        className={`ProductSelect__box ${
          newBundleInCart && !isAvailableNewBundleInCart
            ? 'ProductSelect__box--hide'
            : ''
        } ${newBundleInCart ? 'ProductSelect__box--active' : ''}`}
        data-testid="ProductSelect__box"
      >
        <p data-type="orchestrator_basic">{sku.sku_name}</p>
        {!newBundleInCart && (
          <a
            href="#"
            className="ProductSelect__box__CTA"
            data-testid="ProductSelect__box__CTA"
            onClick={() => {
              handleNewBundleOptions(
                sku.sku_name,
                sku._id,
                sku.product_code
              );
            }}
          >
            {t('rfq_hapo_select_orchestrator_cta', 'Select')}
          </a>
        )}

        {newBundleInCart && (
          <a
            href="#"
            className="ProductSelect__box__CTA"
            data-testid="ProductSelect__box__CTA"
            onClick={handleNewBundleResetCart}
            data-type={sku.sku_name}
            role="tab"
            aria-selected={isAvailableNewBundleInCart ? 'true' : 'false'}
          >
            {t(
              'rfq_hapo_reset_new_orchestrator_cta',
              'Reset and choose another'
            )}
          </a>
        )}
      </div>
    );
  });

  // Fired when radio buttons add to existing vs buy products new bundle clicked
  const handleOnPremSelectedOption = (
    selectionType: 'existing-bundle' | 'new-bundle'
  ) => {
    if (selectionType === 'existing-bundle') {
      setOnPremSelectedOption('existing-bundle');
      setOnPremExistingOrchestratorOptions(true);
      setOnPremNewOrchestratorOptions(false);
      props.handleOnPremBundleChange(dispatch, existingBundleBundleType);
    } else {
      setOnPremSelectedOption('new-bundle');
      setOnPremNewOrchestratorOptions(true);
      setOnPremExistingOrchestratorOptions(false);
      setIsOnPremExistingOrchestratorSelectActive(false);
      if (
        newBundleBundleType === CLOUD &&
        !newBundleInCart?.sku_name?.includes(
          NewAutomationCloudBundleOption.sku_name
        )
      ) {
        handleNewBundleOptions(
          NewAutomationCloudBundleOption.sku_name,
          NewAutomationCloudBundleOption.sku_id,
          NewAutomationCloudBundleOption.product_code
        );
      }
      if (
        newBundleBundleType === AUTOMATION_SUITE &&
        !newBundleInCart?.sku_name?.includes(
          NewAutomationSuiteBundleOption.sku_name
        )
      ) {
        handleNewBundleOptions(
          NewAutomationSuiteBundleOption.sku_name,
          NewAutomationSuiteBundleOption.sku_id,
          NewAutomationSuiteBundleOption.product_code
        );
      }
      // If there is already a new orch of the same type then change focus
      props.handleOnPremBundleChange(dispatch, newBundleBundleType);
    }
  };

  const handleOnPremExistingBundleSelectList = (e: any) => {
    setIsOnPremExistingOrchestratorSelectActive(
      !isOnPremExistingOrchestratorSelectActive
    );
  };

  const handleNewBundleOptions = (
    skuName: string,
    skuId: string,
    productCode: string
  ) => {
    props.handleAddBundleToCart(
      dispatch,
      skuName,
      skuId,
      productCode,
      newBundleBundleType
    );
  };

  const handleNewBundleResetCart = (e: any) => {
    props.handleResetNewBundle(dispatch, newBundleInCart!._id);
  };

  // Methods
  const handleOrchestratorSelect = (
    name: string,
    license: string,
    displayName: string,
    e: any
  ) => {
    props.handleSelectExistingBundleDropdown(
      dispatch,
      name,
      license,
      existingBundleBundleType,
      displayName
    );

    setIsOnPremExistingOrchestratorSelectActive(false);
  };

  const {
    defaultLicenseCode,
    defaultLicenseName,
  } = getDefaultExistingOptionsDisplayNameCode();

  return (
    <div
      className="RequestForQuote__onPremOrchestratorBg"
      data-testid="RequestForQuote__onPremOrchestratorBg"
    >
      <Container>
        {/* OnPrem Orchestrator Products */}
        <styles.ProductSelect>
          {/* Add products to an existing orchestrator */}
          <div
            className="RequestForQuote__onPremExistingOrchestratorOptions"
            onClick={e => {
              handleOnPremSelectedOption('existing-bundle');
            }}
          >
            <Radio
              checked={onPremExistingOrchestratorOptions}
              value="existing-bundle"
              name="radio-existing-bundle"
              inputProps={{ 'aria-label': existingBundleBundleType }}
            />
            <label htmlFor="radio-existing-bundle">
              {addToExistingBundleText}
            </label>
          </div>

          {/* Drop down list of  */}
          <div className="RequestForQuote__onPremExistingOrchestratorOptions__selectWrapper">
            <div
              className={`RequestForQuote__onPremExistingOrchestratorOptions__selectList ${
                onPremExistingOrchestratorOptions
                  ? 'RequestForQuote__onPremExistingOrchestratorOptions__selectList--active'
                  : ''
              }`}
              onClick={handleOnPremExistingBundleSelectList}
            >
              <div className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem">
                <p
                  className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__title"
                  data-testid="bundleOptions__selectedItem__title"
                >
                  {defaultLicenseName || selectBundlePromptText}
                </p>
                <p className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__licenceCode">
                  {defaultLicenseCode}
                </p>
              </div>

              <img
                className={`RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__arrowIcon ${
                  isOnPremExistingOrchestratorSelectActive
                    ? 'RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__arrowIcon--active'
                    : ''
                }`}
                src={ArrowDown}
                alt="Arrow Down"
                data-testid="onPremExistingOrchestratorOptions__selectedItem__arrowIcon"
              />
            </div>

            <div
              className={`RequestForQuote__onPremExistingOrchestratorOptions__dropdown ${
                isOnPremExistingOrchestratorSelectActive
                  ? 'RequestForQuote__onPremExistingOrchestratorOptions__dropdown--active'
                  : ''
              }`}
            >
              {getExistingBundleList()}
            </div>
          </div>

          {/* Add new bundle */}
          <>
            <div
              className="RequestForQuote__onPremNewOrchestratorOptions"
              data-testid="RequestForQuote__onPremNewOrchestratorOptions"
              onClick={e => {
                handleOnPremSelectedOption('new-bundle');
              }}
            >
              <Radio
                checked={onPremNewOrchestratorOptions}
                value="new-bundle"
                name="radio-new-bundle"
                inputProps={{ 'aria-label': newBundleBundleType }}
              />
              <label htmlFor="radio-new-bundle">
                {newBundleRadioLabelText}
              </label>
            </div>
            {availableNewBundles.length > 0 && (
              <div
                className={`ProductSelect__radioWrapper ${
                  onPremNewOrchestratorOptions
                    ? 'ProductSelect__radioWrapper--active'
                    : ''
                }`}
              >
                {getNewBundlesList()}
              </div>
            )}
          </>
        </styles.ProductSelect>
      </Container>
    </div>
  );
};

const getBundleTypeConstants = (bundleType: string) => {
  let existingBundleBundleType = EXISTING_ORCHESTRATOR;
  let newBundleBundleType = NEW_ORCHESTRATOR;

  if (bundleType === 'pm server') {
    existingBundleBundleType = EXISTING_PROCESS_MINING;
    newBundleBundleType = NEW_PROCESS_MINING;
  } else if (bundleType === 'cloud') {
    existingBundleBundleType = EXISTING_CLOUD;
    newBundleBundleType = CLOUD;
  } else if (bundleType === 'automation suite') {
    existingBundleBundleType = EXISTING_AUTOMATION_SUITE;
    newBundleBundleType = AUTOMATION_SUITE;
  }

  return {
    existingBundleBundleType,
    newBundleBundleType,
  };
};

const noExistingBundlesComp = (noExistingBundlesText: string) => (
  <div className="RequestForQuote__onPremExistingOrchestratorOptions__dropdownItem">
    <div className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem">
      <p className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__title">
        {noExistingBundlesText}
      </p>
      <p className="RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__licenceCode">
        -----
      </p>
    </div>
  </div>
);

export default RequestForQuoteBundleOptions;
