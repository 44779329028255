import type { LocalizedString } from '../utils/localization';

// Status filter for open support/premium cases
export const openCasesFilters = [
  'New',
  'Customer Action',
  'On Hold - Dev',
  'In Process',
  'Resolved',
];

export const CASE_OUT_OF_SUPPORT_DECLINED = 'Out of support - Declined';
export const REOPEN_CASE_WINDOW_DAYS = 15;
export const FOLLOWUP_CASE_WINDOW_DAYS = 180;
export const ELEVATE_ESCALATION_WINDOW_HOURS = 12;

export const LICENSE_EXPIRED_WINDOW_DAYS = 14;

export const MAX_EMAIL_BODY_LENGTH = 100000;

// Status filter for closed support/premium cases
export const closedCasesFilters = [ 'Closed' ];

// Status filter for all support/premium cases
export const allCasesFilters = openCasesFilters.concat(closedCasesFilters);

export enum SupportCaseType {
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
  LICENSE_QUERIES_AND_ACTIVATION = 'LICENSE_QUERIES_AND_ACTIVATION',
}

export const LICENSE_VALIDATION_API_ERROR: LocalizedString = {
  textKey: 'license_validation_api_error',
  fallbackText: 'There was an error while validating the license code!',
};

export const INVALID_ENTITLEMENT_CASE_CREATION_ERROR: LocalizedString = {
  textKey: 'invalid_entitlement_case_creation_error',
  fallbackText: 'For support on this product, visit <a href=\'{{sapSupportUrl}}\' target="_blank">SAP Support</a>.',
};

export const INVALID_LICENSE: LocalizedString = {
  textKey: 'invalid_license_error',
  fallbackText: 'This license code cannot be used for Customer Portal access.',
};

export const VALID_LICENSE: LocalizedString = {
  textKey: 'valid_license_message',
  fallbackText: 'The license code was successfully validated!',
};

export const TRIAL_LICENSE_ERROR: LocalizedString = {
  textKey: 'trial_license_error',
  fallbackText: 'Trial Licenses cannot be used. Please use a full license.',
};

export const INVALID_SITE_URL: LocalizedString = {
  textKey: 'invalid_site_url_error',
  fallbackText: 'The URL is not a valid URL. Please make sure to follow the format mentioned above.',
};

export const LICENSE_VALIDATION_WARNING: LocalizedString = {
  textKey: 'license_id_validation_warning',
  fallbackText: 'Your license code and deployment type don\'t match, but this won\'t stop your support request. Double-check the code for faster resolution, or proceed if you\'re sure it\'s correct.',
};

export const SELF_REGISTRATION_DISABLED_ERROR: LocalizedString = {
  textKey: 'self_registration_disabled_error',
  fallbackText: 'Your administrator does not allow users to register for this account. Please reach out to your admin. If you believe this may be an error, please contact your UiPath Account team for assistance.<br/><br/>To contact support, please visit our <a href=\'{{supportFormPath}}\'>Support Request</a> form. Note that if your company manages your implementation, you should contact your internal IT group for assistance.',
};

export const INVALID_ENTITLEMENT_ERROR: LocalizedString = {
  textKey: 'invalid_entitlement_error',
  fallbackText: 'Unable to register for Customer Portal with the provided licenses. <br/><br/>For support on this product, visit <a href=\'{{sapSupportUrl}}\' target="_blank">SAP Support</a>.',
};

export const MAX_DESCRIPTION_LENGTH: number = 32000;

export const supportCaseTypes = [
  {
    type: SupportCaseType.TECHNICAL_SUPPORT,
    title: {
      textKey: 'support_form_case_type_technical_support_title',
      fallbackText: 'Technical Support',
    },
    description: {
      textKey: 'support_form_case_type_technical_support_description',
      fallbackText:
        'Please use this form to submit technical support requests related to your licensed UiPath products.',
    },
  },
  {
    type: SupportCaseType.LICENSE_QUERIES_AND_ACTIVATION,
    title: {
      textKey: 'support_form_case_type_license_and_activation_title',
      fallbackText: 'License Queries & Activation',
    },
    description: {
      textKey: 'support_form_case_type_license_and_activation_description',
      fallbackText:
        'Please use the form below to submit Licensing Queries & Activation requests related to UiPath Studio, UiPath Robot or UiPath Orchestrator.',
    },
  },
];

export const EOL_END_OF_MAINSTREAM_SUPPORT: LocalizedString = {
  textKey: 'support_form_eol_end_of_mainstream_support',
  fallbackText: 'The selected version has reached End of Mainstream Support. Once Mainstream Support ends, Extended Support is provided for one additional year on a best-effort basis. Please view our <a href=\'https://docs.uipath.com/overview/other/latest/overview/product-lifecycle\' target=\'_blank\'>Product Lifecycle</a> page for release information. View the <a href=\'https://docs.uipath.com\' target=\'_blank\'>Product Documentation</a> for help with installing and upgrading.',
};

export const EOL_END_OF_EXTENDED_SUPPORT_BEFORE_THRESHOLD: LocalizedString = {
  textKey: 'support_form_eol_end_of_extended_support_before_threshold',
  fallbackText: 'As per our standard <a href=\'https://docs.uipath.com/overview/other/latest/overview/general-support-terms\' target=\'_blank\'>lifecycle policies</a>, the selected version has reached End of Support and incident support and critical security fixes will be provided as best effort until Oct. 31, 2023. View the <a href=\'https://docs.uipath.com\' target=\'_blank\'>Product Documentation</a> for help with installing and upgrading.',
};

export const EOL_END_OF_EXTENDED_SUPPORT_AFTER_THRESHOLD: LocalizedString = {
  textKey: 'support_form_eol_end_of_extended_support_after_threshold',
  fallbackText: 'As per our standard <a href=\'https://docs.uipath.com/overview/other/latest/overview/general-support-terms\' target=\'_blank\'>lifecycle policies</a>, the selected version has reached End of Support, and is no longer eligible for assisted support. The support request will be reviewed by the support team for a final decision. View <a href=\'https://docs.uipath.com\' target=\'_blank\'>Product Documentation</a> for help with planning, installing and upgrading.',
};

export const LICENSE_PLACEHOLDER: LocalizedString = {
  textKey: 'support_from_license_code_placeholder',
  fallbackText: 'Select a license if listed, or manually type in and hit ↵ Enter',
};

export const LICENSE_HELP_TEXT: LocalizedString = {
  textKey: 'support_form_license_code_help_text',
  fallbackText: 'Please provide your license number to expedite the resolution process. You can select from the dropdown list or enter the license number manually if your product isn\'t listed',
};

export enum DeploymentTypeOption {
  AUTOMATION_CLOUD = 'Automation Cloud',
  AUTOMATION_SUITE = 'Automation Suite',
  STANDALONE_SERVER_PRODUCT = 'Standalone Server Product',
  DESKTOP_VIRTUAL_DESKTOP_CLIENT = 'Desktop/Virtual Desktop Client',
  NOT_APPLICABLE = 'Not Applicable',
}

export enum LicenseDeploymentType {
  SERVICE_FABRIC = 'service-fabric',
  CLOUD = 'cloud',
  STUDIO = 'Studio',
  TCMSI = 'TCMSI',
  PMMSI = 'PMMSI',
  ORCHESTRATORMSI = 'OrchestratorMSI',
  CVMSI = 'CVMSI',
  ROBOT = 'Robot',
  AHSTANDALONE = 'AHStandalone',
  NO_DEPLOYMENT_TYPE = 'NoDeploymentType',
}

export const LicenseAndDeploymentTypeMatch = {
  [DeploymentTypeOption.AUTOMATION_CLOUD]: [ LicenseDeploymentType.CLOUD ],
  [DeploymentTypeOption.AUTOMATION_SUITE]: [ LicenseDeploymentType.SERVICE_FABRIC ],
  [DeploymentTypeOption.STANDALONE_SERVER_PRODUCT]: [
    LicenseDeploymentType.STUDIO,
    LicenseDeploymentType.TCMSI,
    LicenseDeploymentType.PMMSI,
    LicenseDeploymentType.ORCHESTRATORMSI,
    LicenseDeploymentType.CVMSI,
    LicenseDeploymentType.ROBOT,
    LicenseDeploymentType.AHSTANDALONE,
  ],
  [DeploymentTypeOption.DESKTOP_VIRTUAL_DESKTOP_CLIENT]: [
    LicenseDeploymentType.STUDIO,
    LicenseDeploymentType.TCMSI,
    LicenseDeploymentType.PMMSI,
    LicenseDeploymentType.ORCHESTRATORMSI,
    LicenseDeploymentType.CVMSI,
    LicenseDeploymentType.ROBOT,
    LicenseDeploymentType.AHSTANDALONE,
  ],
  [DeploymentTypeOption.NOT_APPLICABLE]: [],
};

export enum SupportFormSection {
  DESCRIBE_ISSUE = 'Describe Issue',
  ADDITIONAL_DETAILS = 'Additional Details',
  CREATE_CASE = 'Create Case',
}

export const supportFormSections = [
  {
    section: SupportFormSection.DESCRIBE_ISSUE,
    textKey: 'support_form_describe_issue_section',
    fallbackTect: 'Describe Issue',
  },
  {
    section: SupportFormSection.ADDITIONAL_DETAILS,
    textKey: 'support_form_additional_details_section',
    fallbackTect: 'Additional Details',
  },
  {
    section: SupportFormSection.CREATE_CASE,
    textKey: 'support_form_create_case_section',
    fallbackTect: 'Create Case',
  },
];

export enum VersionDropdownField {
  STUDIO_OR_ROBOT_VERSION = 'studioOrRobotVersion',
  ORCHESTRATOR_VERSION = 'orchestratorVersion',
  PRODUCT_COMPONENT_VERSION = 'productComponentVersion',
  AUTOMATION_SUITE_VERSION = 'automationSuiteVersion',
  NONE = 'none',
}

export const studioAndRobotVersionsProduct = [ 'Studio', 'Studio X', 'Robot' ];

export const orchestratorProduct = [ 'Orchestrator' ];

export const COMMUNITY_LICENSES = [ 'KIT', 'COM' ];

export const publicConfirmationRegisteredUser = {
  title: {
    textKey: 'support_form_public_confirmation_registered_user_text_1',
    fallbackText: '{{email}} is currently an active user of the UiPath Customer Portal.',
  },
  description: {
    textKey: 'support_form_public_confirmation_registered_user_text_2',
    fallbackText: 'This access enables you to monitor and adjust your support cases, delve into a wide range of technical solution articles, download products, and enjoy various other benefits.',
  },
  prompt: {
    textKey: 'support_form_public_confirmation_registered_user_prompt',
    fallbackText: 'Login to your Customer Portal Account',
  },
};

export const publicConfirmationUnRegisteredUserMatchedLicense = {
  title: {
    textKey: 'support_form_public_confirmation_unregistered_user_matched_license_text_1',
    fallbackText: '{{email}} has been successfully added to the UiPath Customer Portal.',
  },
  description: {
    textKey: 'support_form_public_confirmation_unregistered_user_matched_license_text_2',
    fallbackText: 'With this access, you can track and modify your support cases, explore extensive technical solution articles, access product downloads, and more. Finalize your registration by logging in or creating an account with {{email}}.',
  },
  prompt: {
    textKey: 'support_form_public_confirmation_unregistered_user_matched_license_prompt',
    fallbackText: 'Continue with Customer Portal Login',
  },
};

export const publicConfirmationUnRegisteredUserUnMatchedLicense = {
  title: {
    textKey: 'support_form_public_confirmation_unregistered_user_unmatched_license_text_1',
    fallbackText: 'Enhance your support experience by registering for the UiPath Customer Portal.',
  },
  description: {
    textKey: 'support_form_public_confirmation_unregistered_user_unmatched_license_text_2',
    fallbackText: 'By gaining access, you\'ll have the ability to monitor and update your support cases, delve into a wealth of technical solution articles, download various products, and enjoy many other advantages.',
  },
  prompt: {
    textKey: 'support_form_landing_page_register_for_customer_portal',
    fallbackText: 'Register for Customer Portal',
  },
};

export const publicLandingPage = {
  description1: {
    textKey: 'support_form_welcome_description_login',
    fallbackText: 'Enhance your experience by logging into your Customer Portal Account. Enjoy benefits such as smart case information collection and exclusive access to detailed troubleshooting guides.',
  },
  description2: {
    textKey: 'support_form_welcome_description_guest',
    fallbackText: 'Alternatively, you can proceed as a guest by contacting support with your enterprise license number.',
  },
  button1: {
    textKey: 'support_form_landing_page_login_button',
    fallbackText: 'Login to Customer Portal',
  },
  button2: {
    textKey: 'support_form_landing_page_continue_support_button',
    fallbackText: 'Continue with Support Request',
  },
};

export const CloudUnregisteredLandingPage = {
  description1: {
    textKey: 'support_form_unregistered_welcome_description',
    fallbackText: 'The UiPath Customer Portal offers exclusive access to essential information, insights, and accelerated services for the UiPath Platform. It is designed specifically for organizations to manage their UiPath products, obtain platform-related information, and interact with UiPath teams.',
  },
  description2: {
    textKey: 'support_form_unregistered_welcome_description_2',
    fallbackText: 'Why Register?<br /><ul><li><b>Immediate Access:</b> Dive into a wealth of UiPath products and platform-related information.</li><li><b>Enhanced Support:</b> Enjoy a modern, supportive experience designed to address your needs quickly.</li><li><b>Insights & Updates:</b> Stay ahead with key insights and updates tailored to enhance your automation strategies.</li><li>More information is available in the <a href=\'https://www.uipath.com/customer-portal-faq\' target=\'_blank\'>Customer Portal FAQ</a></li></ul>Ready to get started? Register below, or click the second button to skip registration and directly contact support for your UiPath products.',
  },
  button1: {
    textKey: 'support_form_landing_page_register_for_customer_portal',
    fallbackText: 'Register for Customer Portal',
  },
  button2: {
    textKey: 'support_form_unregistered_create_support_button',
    fallbackText: 'Create a Support Request',
  },
};

// Hides the back button on the support details page.
// Used to hide the back button when the user is redirected into CP from public case creation
export const SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM = {
  key: 'hide_back',
  value: 'true',
};

export const SAP_SUPPORT_URL = 'https://me.sap.com/createIssue/0';
