import { Header } from '@customer-portal/constants';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  getAuthType,
  useAuth,
} from '../contexts/auth';
import { StoreContext } from '../store';

export enum RequestType {
  GET,
  POST,
  PATCH,
}

/**
 * @param url request url
 * @param requestType request type eg GET, POST, PATCH
 * @param manual request should run manully otherwise the request runs as soon as the hook is initialized
 *               (its false by default)
 * @param data request data
 * @param config request configurations
 */
type AxiosProp = {
  url: string;
  requestType: RequestType;
  manual?: boolean;
  data?: any;
  config?: AxiosRequestConfig;
};

export function useAxios({
  url,
  requestType,
  manual = false,
  data,
  config,
}: AxiosProp) {
  const [ response, setResponse ] = useState<any>({});
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  useEffect(() => {
    if (!manual) {
      getResponse();
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [ response, error ]);

  async function getResponse(
    reqUrl: string = url,
    reqData: any = data,
    reqConfig: AxiosRequestConfig | undefined = config
  ) {
    const headers = reqConfig?.headers;
    setLoading(true);
    const reqParameters = {
      ...reqConfig,
      headers: {
        ...headers,
        Authorization: `Bearer ${await getAccessToken()}`,
        [Header.SELECTED_ACCOUNT_ID]: state.companyId,
        [Header.AUTH_TYPE]: getAuthType(),
      },
    };
    try {
      let res;
      switch (requestType) {
        case RequestType.GET:
          res = await axios.get(reqUrl, reqParameters);
          break;
        case RequestType.POST:
          res = await axios.post(reqUrl, reqData, reqParameters);
          break;
        case RequestType.PATCH:
          res = await axios.patch(reqUrl, reqData, reqParameters);
          break;
        default:
          break;
      }
      setResponse(res);
    } catch (err) {
      setError(err);
    }
  }

  const refetch = (
    newUrl?: string,
    newData?: any,
    newConfig?: AxiosRequestConfig | undefined
  ) => {
    getResponse(newUrl ?? url, newData ?? data, newConfig ?? config);
  };

  return [ response, loading, error, refetch ];
}

/**
 * Default calls to CP Node to put in the default values
 */
export async function axiosGet<T = any>(
  url: string,
  companyId: string,
  accessToken: string,
  config?: AxiosRequestConfig | undefined
) {
  const headers = config?.headers;

  return axios.get<T>(url, {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      [Header.SELECTED_ACCOUNT_ID]: companyId,
      [Header.AUTH_TYPE]: getAuthType(),
      'cache-control': 'no-cache',
    },
  });
}

export async function axiosPost<T = any>(
  url: string,
  companyId: string,
  accessToken: string,
  data?: any,
  config?: AxiosRequestConfig | undefined
) {
  const headers = config?.headers;
  return axios.post<T>(url, data, {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      [Header.SELECTED_ACCOUNT_ID]: companyId,
      [Header.AUTH_TYPE]: getAuthType(),
      'cache-control': 'no-cache',
    },
  });
}

export async function axiosPatch<T = any>(
  url: string,
  companyId: string,
  accessToken: string,
  data?: any,
  config?: AxiosRequestConfig | undefined
) {
  const headers = config?.headers;
  return axios.patch<T>(url, data, {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      [Header.SELECTED_ACCOUNT_ID]: companyId,
      [Header.AUTH_TYPE]: getAuthType(),
      'cache-control': 'no-cache',
    },
  });
}

export async function axiosPublicGet<T = any>(
  url: string,
  config?: AxiosRequestConfig | undefined
) {
  const headers = config?.headers;

  return axios.get<T>(url, {
    ...config,
    withCredentials: true,
    headers: { ...headers },
  });
}

export async function axiosPublicPost<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig | undefined
) {
  const headers = config?.headers;
  return axios.post<T>(url, data, {
    ...config,
    withCredentials: true,
    headers: { ...headers },
  });
}
