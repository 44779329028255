import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as styled from '../../assets/css/KnownIssues/index';
import {
  DetailsLink,
  ListTag,
  ListTagContainer,
  LoaderContainer,
  Popup,
} from '../../assets/css/KnownIssues/index';
import { NewCPTableContainer } from '../../assets/css/NewTable';
import { axiosGet } from '../../client/axios';
import Loader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import { CustomEventType } from '../../constants/caseAssist.constants';
import { KNOWN_ISSUES_URL } from '../../constants/network.constants';
import { KNOWN_ISSUES_PAGEVIEW } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../store';
import { logCustomEvent } from '../../utils/caseAssist';
import { featureFlag } from '../../utils/featureFlag';
import CustomerPortalPage404 from '../404';

const CustomerPortalKnownIssues = (props: any) => {
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();
  const { state } = useContext(StoreContext);
  const [ knownIssuesList, setKnownIssuesList ] = useState([]);
  const [ isKnownIssuesEnabled, setIsKnownIssuesEnabled ] = useState<any>(null);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const getKnownIssuesData = async () => {
    try {
      const knownIssuesData = await axiosGet(
        KNOWN_ISSUES_URL,
        state.companyId,
        await getAccessToken()
      );
      if (knownIssuesData.status === 200 && knownIssuesData.data) {
        setKnownIssuesList(knownIssuesData.data);
        logCustomEvent(
          CustomEventType.KNOWN_ISSUES_VIEW,
          {},
          state.companyId,
          await getAccessToken(),
        );
        triggerTrackEventWithStateData(KNOWN_ISSUES_PAGEVIEW, {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateKnownIssuesEnabled = async () => {
    try {
      const { companyId } = state;

      const isKnownIssuesFeatureEnabled = await featureFlag.isKnownIssuesEnabled(
        companyId,
        await getAccessToken()
      );

      setIsKnownIssuesEnabled(isKnownIssuesFeatureEnabled);
    } catch (e) {
      setIsKnownIssuesEnabled(false);
    }
  };

  useEffect(() => {
    updateKnownIssuesEnabled();
    getKnownIssuesData();
  }, []);

  const handleOnMouseOver = (column: string, key: string) => () => {
    if (column === 'summary') {
      const summaryTextElement = document.getElementById(`summary-text-${key}`);
      if (summaryTextElement) {
        // check if the text is more than 3 lines, show popup of full text
        const lineHeight = parseFloat(getComputedStyle(summaryTextElement).lineHeight);
        const maxHeight = summaryTextElement.scrollHeight;
        const lineText = Math.floor(maxHeight / lineHeight);
        if (lineText > 3) {
          const summaryPopupElement = document.getElementById(`summary-popup-${key}`);
          if (summaryPopupElement) {
            summaryPopupElement.style.display = 'block';
          }
        }
      }
    } else {
      const versionsPopupElement = document.getElementById(`${column}-popup-${key}`);
      if (versionsPopupElement) {
        versionsPopupElement.style.display = 'block';
      }
    }
  };

  const handleOnMouseOut = (column: string, key: string) => () => {
    const element = document.getElementById(`${column}-popup-${key}`);
    if (element) {
      element.style.display = 'none';
    }
  };

  if (isKnownIssuesEnabled === null) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  } else if (isKnownIssuesEnabled === false) {
    return (<CustomerPortalPage404 />);
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'known_issues_page_title',
            'Known Issues | Customer Portal'
          )}
        </title>
      </Helmet>
      <styled.KnownIssuesContainer>
        <Container>
          <styled.Title>
            {t(
              'known_issues_hero_title',
              'UiPath Known Issues:\nWe\'re Working On It'
            )}
          </styled.Title>
          <styled.Subtitle>
            {t(
              'known_issues_hero_description',
              'This page lists known issues with UiPath products. The information provided is meant to educate and offer guidance or temporary solutions while permanent fixes are underway.'
            )}
          </styled.Subtitle>
          <NewCPTableContainer>
            <Table>
              <TableHead>
                <TableRow className='heading'>
                  <TableCell className='statusColumn'>Status</TableCell>
                  <TableCell className='issueIdentifierColumn'>Issue Identifier</TableCell>
                  <TableCell className='summaryColumn'>Summary</TableCell>
                  <TableCell className='productColumn'>Product</TableCell>
                  <TableCell className='affectsVersionsColumn'>Affects Versions</TableCell>
                  <TableCell className='fixVersionsColumn'>Fix Versions</TableCell>
                  <TableCell className='dateCreatedColumn'>Date Created</TableCell>
                </TableRow>
              </TableHead>
              {knownIssuesList.length !== 0 && (
                <TableBody>
                  {knownIssuesList.map((issue: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <ListTag
                          className={issue.status === 'In Progress' ? 'yellow' :
                            issue.status === 'Fixed' ? 'green' : 'red'}>
                          {issue.status}
                        </ListTag>
                      </TableCell>
                      <TableCell><DetailsLink to={`/known-issues/${issue.key}`}>{issue.key}</DetailsLink></TableCell>
                      <TableCell>
                        <DetailsLink
                          to={`/known-issues/${issue.key}`}
                          id={`summary-text-${issue.key}`}
                          className='summary'
                          onMouseOver={handleOnMouseOver('summary', issue.key)}
                          onMouseOut={handleOnMouseOut('summary', issue.key)}>
                          {issue.summary}
                        </DetailsLink>
                        <Popup
                          id={`summary-popup-${issue.key}`}
                          className='summary'>
                          {issue.summary}
                        </Popup>
                      </TableCell>
                      <TableCell>{issue.components}</TableCell>
                      <TableCell>
                        <ListTagContainer>
                          {issue.affectsVersions.map((version: any, versionIndex: number) => (
                            versionIndex < 4 && <ListTag key={versionIndex}>{version}</ListTag>
                          ))}
                          {issue.affectsVersions.length > 4 && (
                            <div>
                              <ListTag
                                className='additional'
                                onMouseOver={handleOnMouseOver('affects-versions', issue.key)}
                                onMouseOut={handleOnMouseOut('affects-versions', issue.key)}>
                                  +
                                {issue.affectsVersions.length - 4}
                              </ListTag>
                              <Popup
                                id={`affects-versions-popup-${issue.key}`}
                                className='versions'>
                                <ListTagContainer>
                                  {issue.affectsVersions.map((version: any, versionIndex: number) => (
                                    versionIndex >= 4 && <ListTag key={versionIndex}>{version}</ListTag>
                                  ))}
                                </ListTagContainer>
                              </Popup>
                            </div>
                          )}
                        </ListTagContainer>
                      </TableCell>
                      <TableCell>
                        <ListTagContainer>
                          {issue.fixVersions.map((version: any, versionIndex: number) => (
                            versionIndex < 4 && <ListTag key={versionIndex}>{version}</ListTag>
                          ))}
                          {issue.fixVersions.length > 4 && (
                            <div>
                              <ListTag
                                className='additional'
                                onMouseOver={handleOnMouseOver('fix-versions', issue.key)}
                                onMouseOut={handleOnMouseOut('fix-versions', issue.key)}>
                                  +
                                {issue.fixVersions.length - 4}
                              </ListTag>
                              <Popup
                                id={`fix-versions-popup-${issue.key}`}
                                className='versions'>
                                <ListTagContainer>
                                  {issue.fixVersions.map((version: any, versionIndex: number) => (
                                    versionIndex >= 4 && <ListTag key={versionIndex}>{version}</ListTag>
                                  ))}
                                </ListTagContainer>
                              </Popup>
                            </div>
                          )}
                        </ListTagContainer>
                      </TableCell>
                      <TableCell>{issue.dateCreated}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {knownIssuesList.length === 0 && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </NewCPTableContainer>
        </Container>
      </styled.KnownIssuesContainer>
    </>
  );
};

export default CustomerPortalKnownIssues;
