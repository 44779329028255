import type { Locale } from '@customer-portal/constants';
import {
  AuthType,
  UserRole,
  UserStatus,
} from '@customer-portal/constants';
import type { SetUserAPIResponse } from '@customer-portal/interfaces';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactGA from 'react-ga';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';

import {
  axiosGet,
  axiosPatch,
  axiosPost,
} from './client/axios';
// Clients
import { fetchCompanyLicenses } from './client/licenses';
// Components
import ActionPanel from './components/ActionPanel';
import ActivateAdminUserModal from './components/ActivateAdminUser';
import AnnouncementModal from './components/AnnouncementModal';
import CollabDownload from './components/collab/Collab-Download';
import CustomerPortalBanner from './components/CustomerPortalBanner';
import FileDownload from './components/FileUpload/FileDownload';
import Footer from './components/Footer';
import Header from './components/Header';
import NotificationBanner from './components/Notification-Banner';
import PrivateRoute from './components/PrivateRoute';
import StickyMessageBanner from './components/StickyMessageBanner';
import GetUserInfoModal from './components/user_info/GetUserInfoModal';
import { FETCH_ANNOUNCEMENTS_INTERVAL } from './constants/announcement.constants';
import { getValidLanguage } from './constants/localization.constants';
import {
  SET_USER_LANGUAGE_URL,
  UNREGISTERED_PATH_PREFIX,
  USER_ANNOUNCEMENT_URL,
} from './constants/network.constants';
import {
  CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY,
  CP_POST_AUTH_REDIRECT_URL_KEY,
} from './constants/state.constants';
// Constants
import {
  SET_USER,
  UPDATE_GLOBAL_STATE,
} from './constants/user.constants';
import { LocaleToAzureTranslatorLocale } from './constants/webchat.constants';
import {
  getAuthType,
  useAuth,
} from './contexts/auth';
import AuthGuard from './guards/auth';
import useShowPublicExperience from './hooks/useShowPublicExperience';
import useUpdateDetector from './hooks/useUpdateDetector';
import { setAppInsAuthenticatedUser } from './lib/AppInsights/AppInsights';
import AuthUserUtil from './lib/auth.util';
import CompanyHelper from './lib/company.utils';
// Utils
import { UserPermissionsHelper } from './lib/UserPermissions';
import CustomerPortalPage401 from './pages/401';
import CustomerPortalPage403 from './pages/403';
import CustomerPortalPage404 from './pages/404';
import CustomerPortalPage500 from './pages/500';
import ActivateAccountPage from './pages/ActivateAccount';
import CustomerPortalPageAddCase from './pages/AddCase';
import CustomerPortalPageAddPremiumCase from './pages/AddPremiumCase';
import CustomerPortalPageAnalytics from './pages/Admin/analytics';
import CustomerPortalPageRFQHAPOAnalytics from './pages/Admin/CustomerPortalPageAnalyticsRFQHAPO';
import CustomerPortalPageHAPOManagement from './pages/Admin/CustomerPortalPageHAPOManagement';
import CustomerPortalPageHAPOManagementEntitlement from './pages/Admin/CustomerPortalPageHAPOManagementEntitlement';
import CustomerPortalPageAdminKBCategories from './pages/Admin/knowledge-categories';
import CustomerPortalPageProductCatalogManagement from './pages/Admin/product-catalog';
import CustomerPortalPageSuperUserAdmin from './pages/Admin/user-management';
import CustomerPortalPageBrowse from './pages/Browse';
import CustomerPortalPageCategory from './pages/Category';
import Checkout from './pages/Checkout';
import CustomerPortalPageCollab from './pages/Collab';
import CustomerPortalPageCompany from './pages/Company';
import { DebugPage } from './pages/Debug';
import CustomerPortalPageAddHistoryOrder from './pages/HAPO/AddHistoryOrder';
import HapoCheckout from './pages/HAPO/Checkout';
import CustomerPortalPageEditPastRequest from './pages/HAPO/EditPastRequest';
import HAPORequestHistory from './pages/HAPO/Request-History';
import HapoShop from './pages/HapoShop';
// Pages
import Home from './pages/Home/';
import KBSharedLinkComponent from './pages/KBShare';
import CustomerPortalPageKnowledge from './pages/Knowledge';
import CustomerPortalKnownIssues from './pages/KnownIssues';
import CustomerPortalKnownIssuesDetails from './pages/KnownIssuesDetails';
import CustomerPortalPageNotifications from './pages/Notifications';
import CPProductDownloads from './pages/ProductDownloads';
import CustomerPortalPageEditProfile from './pages/Profile';
import CustomerPortalPublicLandingPage from './pages/PublicLanding';
import RequestForQuote from './pages/RequestForQuote';
import RestrictedSupport from './pages/restrictedSupport';
import CustomerPortalPageSearch from './pages/Search';
import CustomerPortalPageSupport from './pages/Support';
import CustomerPortalSupportDetails from './pages/SupportDetails';
import CustomerPortalSupportKB from './pages/SupportKB';
import CustomerPortalSupportKBCategory from './pages/SupportKBCategory';
import CustomerPortalSupportKBDetails from './pages/SupportKBDetails';
import CustomerPortalUnregisteredLandingPage from './pages/UnregisteredLanding';
import VerifyEmailPage from './pages/VerifyEmail';
// Contexts
import { StoreContext } from './store';
import { getUrlWithAllParams } from './utils/cloud';
import { featureFlag } from './utils/featureFlag';
import { hasPublicForceLoginQueryParam } from './utils/publicExperience';
import {
  getChatbotEndpointForLanguage,
  webchatClient,
} from './utils/webchatClient';
// Google Analytics
const trackingID = 'UA-39012418-22';
ReactGA.initialize(trackingID);

const AuthenticatedApp = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { i18n } = useTranslation('common');
  const {
    getAccessToken, user, isAuthenticated,
  } = useAuth();
  const location = useLocation();

  const [ cognigyInitialized, setCognigyInitialized ] = useState<boolean>(false);
  const [ showPrimaryBanner, setShowPrimaryBanner ] = useState<boolean>(true);
  const [ prevLanguage, setPrevLanguage ] = useState<Locale>(i18n.language as Locale);
  const [ showVerifyEmailPage, setShowVerifyEmailPage ] = useState<boolean>(false);
  const authType = getAuthType();

  const isEmailVerified: boolean = AuthUserUtil.isEmailVerified(user);

  // Set through the setUser function below. Means the user is internal, or external with at least one user-company relationship
  const isRegisteredUser: boolean = UserPermissionsHelper.isRegisteredUser();

  const canViewLicenses = UserPermissionsHelper.isViewLicenseAllowed();
  const isUiPathUser = UserPermissionsHelper.isUiPathUser();

  /**
   * Called on every page change to re-fetch the user context from the backend
   */
  const setUser = async () => {
    const isUiPathUserDeselectingCompany = isUiPathUser && location.pathname === '/browse';
    const body = {
      name: AuthUserUtil.getName(user),
      // Ensures that when a UiPather deselects a company, the /setUser call stops automatically placing them in their last selected company
      clearLastSelectedAccount: isUiPathUserDeselectingCompany,
      // The language to initialize newly-created users with in the database
      signupLanguage: i18n.language,
    };

    let userResults;

    try {
      userResults = await axiosPost<SetUserAPIResponse>(
        `${process.env.REACT_APP_BACKEND_HOST_NAME}/api/v1/user/setUser`,
        isUiPathUserDeselectingCompany ? '' : state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      switch (e.response?.status) {
        case 401:
          // TODO: Need to check if this is necessary anymore
          if (e.response?.data?.type === 'Check_Verified_User') {
            dispatch({
              type: 'setIsBlocked',
              payload: false,
            });
          }
          break;
      }
    }

    // Get the response objects
    const {
      currentAdmin,
      currentUserCompany,
      currentUser,
      userCompanies,
    } = userResults?.data ?? {};

    // Handle case where setUser response didn't return a user, which should always be found/created
    if (!currentUser) {
      console.log('Error: This should never happen, as the user should always be found/created after authentication');
      dispatch({
        type: 'setIsRegistered',
        payload: false,
      });
      dispatch({
        type: 'setFinishedInitialUserCheck',
        payload: true,
      });
      dispatch({
        type: 'setBannerMsg',
        payload: 'Something went wrong while setting up your user account. Please try again later.',
      });
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerAutoHide',
        payload: true,
      });
      return;
    }

    const preferredLanguage = currentUser.preferences?.userLanguage ? getValidLanguage(currentUser.preferences.userLanguage) : undefined;
    dispatch({
      type: 'setPreferredLanguage',
      payload: preferredLanguage,
    });

    // If the external user has no user-company relationships, show unregistered experience
    if (!currentUser.isUiPath && userCompanies?.length === 0) {
      dispatch({
        type: 'setIsRegistered',
        payload: false,
      });
      dispatch({
        type: 'setFinishedInitialUserCheck',
        payload: true,
      });
      return;
    }

    let companyId = '';
    const oid = currentUser.oid;

    // Show get user info modal for all auth connection types (social, or basic)
    // if some registration fields are missing or user didn't accept the general terms of use,
    // or if it is being forced to show (due to newly-activated user from case-creation)
    const shouldShowGetUserInfoModal: boolean =
        !currentUser.isUiPath &&
        (
          !!state.forceShowUserInfoModal ||
          (!currentUser.name ||
            !currentUser.country ||
            !currentUser.jobTitle ||
            !!(
              currentUser.preferences &&
              currentUser.preferences.acceptedUiPathGeneralTermsOfUse === false
            )
          )
        );

    if (shouldShowGetUserInfoModal) {
      dispatch({
        type: 'setShowGetUserInfoModal',
        payload: true,
      });
      dispatch({
        type: 'setShowActivateAdminUserModal',
        payload: false,
      });
    } else {
      dispatch({
        type: 'setShowGetUserInfoModal',
        payload: false,
      });
    }

    dispatch({
      type: SET_USER,
      payload: {
        currentUser,
        currentUserCompany,
        currentAdmin,
        userCompanies,
      },
    });
    dispatch({
      type: 'setIsRegistered',
      payload: true,
    });

    if (currentUserCompany?.userCompany) {
      // We use `currentUserCompany.additionalCompanyInfo.id` because `currentUserCompany.userCompany` might not exist for UiPath users
      companyId = currentUserCompany?.additionalCompanyInfo?.id ?? '';
    } else if (currentUser.lastSelectedAccount) {
      for (const { userCompany } of userCompanies ?? []) {
        if (userCompany.companyId === currentUser.lastSelectedAccount) {
          companyId = userCompany.companyId || '';
          break;
        }
      }
    }

    const isVelocityAccount: boolean =
    currentUserCompany?.additionalCompanyInfo?.isVelocityAccount ?? false;
      // set true if no companyInfo or isSoldToPartner
    const isSoldToPartner: boolean =
    currentUserCompany?.additionalCompanyInfo?.isSoldToPartner ?? true;
    const area: string = currentUserCompany?.additionalCompanyInfo?.area ?? '';
    const uuid: string = currentUser.uuid || '';
    const email: string = currentUser.email?.toLowerCase() || '';

    // Retrieve announcements for the user
    try {
      // Don't fetch if showing user-info modal to prevent multiple popups in series, or if announcements were recently fetched
      // In addition, backend will only return announcements if enough time has passed since the user's lastPopupClosedDate
      const shouldFetchAnnouncements = !shouldShowGetUserInfoModal &&
      (!state.userAnnouncements?.fetchedAt || (Date.now() - state.userAnnouncements.fetchedAt) > FETCH_ANNOUNCEMENTS_INTERVAL);

      if (shouldFetchAnnouncements) {
        const announcementResult = await axiosGet(
          USER_ANNOUNCEMENT_URL,
          state.companyId,
          await getAccessToken(),
        );

        if (announcementResult.status === 200 && Array.isArray(announcementResult.data?.announcements)) {
          dispatch({
            type: 'setUserAnnouncements',
            payload: {
              announcements: announcementResult.data.announcements,
              fetchedAt: Date.now(),
            },
          });
        }
      }
    } catch (e) {
      console.log(`Unable to retrieve user's announcements: ${e}`);
    }

    dispatch({
      type: 'setFinishedInitialUserCheck',
      payload: true,
    });

    return {
      uuid,
      email,
      companyId,
      isVelocityAccount,
      isSoldToPartner,
      area,
      oid,
    };
  };

  // Check if path is allowed for chatbots AND if feature flag is on
  const isChatbotEnabled = async (browserUrl: string) => {
    const accessToken = await getAccessToken();
    if (!state.finishedInitialUserCheck) {
      return false;
    }
    const isPathMatch = (
      browserUrl === '/' || ![
        `${UNREGISTERED_PATH_PREFIX}/activate-account`,
        '/support/chat',
        '/debug',
      ].find(path => browserUrl.startsWith(path))
    );

    return isPathMatch && await featureFlag.isChatbotEnabled(state.companyId, accessToken);
  };

  const cognigyEmail = isRegisteredUser ? state.userEmail : AuthUserUtil.getEmail(user);

  useEffect(() => {
    const checkUser = async () => {
      // Don't call the set user API if the user is not authenticated nor verified
      if (!user || !isEmailVerified) {
        dispatch({
          type: 'setIsRegistered',
          payload: false,
        });
        dispatch({
          type: 'setFinishedInitialUserCheck',
          payload: true,
        });
        return;
      }

      dispatch({
        type: UPDATE_GLOBAL_STATE,
        payload: {},
      });

      try {
        const val = await setUser();
        const accessToken = await getAccessToken();
        // companyId is taken from response if customer user; else uipath user
        const companyId = val?.companyId || state.companyId;
        const oid = val?.oid ?? '';
        // set true if no isSoldToPartner
        const isSoldToPartner: boolean = val?.isSoldToPartner ?? true;

        dispatch({
          type: 'setIsSoldToPartner',
          payload: isSoldToPartner,
        });

        // Check if chatbot is enabled and initialize accordingly
        if (!cognigyInitialized && await featureFlag.isChatbotEnabled(companyId, accessToken)) {
          console.log('Should initialize chatbot!');
          const language = i18n.language as Locale;
          webchatClient
            .initialize({
              language,
              settings: {
                disableHtmlContentSanitization: false,
                enableUnreadMessageTitleIndicator: false,
                startBehavior: 'injection',
                getStartedData: {
                  email: cognigyEmail,
                  language: LocaleToAzureTranslatorLocale.get(language),
                  isGuestUser: !isRegisteredUser,
                  url: `${window.location.origin}${getUrlWithAllParams()}`,
                },
              },
            })
            .then(() => {
              setCognigyInitialized(true);
            });
        }

        // Set AppIns user
        setAppInsAuthenticatedUser(oid);
      } catch (e) {
        console.error(`Something went wrong while setting up the user ${e}`);
      }
    };

    checkUser();
  }, [ location.pathname, state.companyId, user, isEmailVerified ]);

  // We want to show the webchat only for specific pages
  useEffect(() => {
    const fn = async () => {
      if (!cognigyInitialized) {
        return;
      }

      const browserUrl = `${location.pathname}${location.search}`;

      if (await isChatbotEnabled(browserUrl) && !state.showActionPanel) {
        webchatClient.show();
      } else {
        webchatClient.hide();
      }
    };

    fn();
  }, [
    cognigyInitialized,
    location.pathname,
    location.search,
    state.showActionPanel,
  ]);

  // Reset notification banner whenever the user navigates away
  useEffect(() => {
    (state.notificationBannerMessages ?? []).forEach((_, i) => {
      closeStickyMessage(i);
    });
  }, [ location.pathname, location.search ]);

  // When the user's preferred language is obtained (from /setUser), update the i18n language if necessary
  useEffect(() => {
    if (state.preferredLanguage && i18n.language !== state.preferredLanguage) {
      i18n.changeLanguage(state.preferredLanguage);
    }
  }, [ state.preferredLanguage ]);

  // Handle when i18n language is changed:
  // Update the user's preferred language in the backend
  // Reinitialize chatbot widget with new endpoint (if applicable)
  useEffect(() => {
    const storePreferredUserLanguage = async (language: Locale) => {
      // Skip backend call if initial user check is not finished or user's preferred language matches
      if (!isEmailVerified || !state.finishedInitialUserCheck || state.preferredLanguage === language) {
        return;
      }

      const accessToken = await getAccessToken();
      const companyId = state.companyId;

      try {
        await axiosPatch(
          `${SET_USER_LANGUAGE_URL}`,
          companyId,
          accessToken,
          { userLanguage: language }
        );
        dispatch({
          type: 'setPreferredLanguage',
          payload: language,
        });
      } catch (e) {
        console.error(`Failed to store user's preferred language: ${e}`);
      }
    };

    const reinitializeChatbot = async (language: Locale) => {
      const prevChatbotEndpoint = getChatbotEndpointForLanguage(prevLanguage);
      const currChatbotEndpoint = getChatbotEndpointForLanguage(language);

      const browserUrl = `${location.pathname}${location.search}`;

      /**
       * Don't do anything if any of the following:
       *  1. The endpoint for the webchat widget for the
       *     corresponding language hasn't changed
       *  2. The chatbot should not be enabled
       */
      if (prevChatbotEndpoint === currChatbotEndpoint || !(await isChatbotEnabled(browserUrl))) {
        return;
      }

      // Otherwise, re-initialize the chatbot widget
      setPrevLanguage(language);
      setCognigyInitialized(false);

      webchatClient
        .deinitialize()
        .then(() => {
          webchatClient
            .initialize({
              language,
              userId: cognigyEmail, // TODO: Remove? This is not used in the first initialization
              settings: {
                disableHtmlContentSanitization: false,
                enableUnreadMessageTitleIndicator: false,
                startBehavior: 'injection',
                getStartedData: {
                  email: cognigyEmail,
                  language: LocaleToAzureTranslatorLocale.get(language),
                  isGuestUser: !isRegisteredUser,
                  url: `${window.location.origin}${getUrlWithAllParams()}`,
                },
              },
            })
            .then(() => {
              setCognigyInitialized(true);
              state.finishedInitialUserCheck ? webchatClient.show() : webchatClient.hide();
            });
        });
    };

    reinitializeChatbot(i18n.language as Locale);
    storePreferredUserLanguage(i18n.language as Locale);
  }, [ i18n.language ]);

  // Check if company has cloud licenses
  const checkCloudLicenses = (licenses: any) => {
    let hasCloudLicenses = false;

    if (licenses && licenses.length > 0) {
      for (const license of licenses) {
        if (
          license.bundle?.name?.includes('Cloud')
        ) {
          hasCloudLicenses = true;
          break;
        }
      }
    }

    return hasCloudLicenses;
  };

  useEffect(() => {
    // Don't fetch company & license data if the user is not authenticated nor verified
    if (!user || !isEmailVerified) {
      return;
    }

    // Fetch the licensing each time when the user is authenticated
    const getCompanyLicenses = async (companyId: string, token: string) => {
      const resultData = await fetchCompanyLicenses(companyId, token, state.userId, dispatch);

      if (resultData) {
        dispatch({
          type: 'setHasCloudLicenses',
          payload: checkCloudLicenses(resultData.boonLicense.licenses),
        });
      } else {
        dispatch({
          type: 'setHasCloudLicenses',
          payload: false,
        });
      }
    };

    // Get Company, logo, maintenance flag, related team users and uipath contacts
    const getCompanyData = async (companyId: string, token: string) => {
      if (!state.isBlocked) {
        try {
          // Fetch CompanyData i.e. Company Type, Team Users and UiPath Contacts
          const companyData = await CompanyHelper.getCompanyData(
            companyId,
            token
          );

          const hasAdminUsers: boolean = companyData.teamUsers.some(
            (teamUser: any) =>
              teamUser.cpRole === UserRole.CP_ADMIN &&
              [ UserStatus.ACTIVE, UserStatus.PENDING ].includes(teamUser.status)
          );

          // Show activate admin user modal after get user info modal,
          // if no CP Admins for the account and the "ask me later" time window expired or undefined
          const shouldShowActivateAdminUserModal: boolean =
            !(!state.isUiPath &&
              (!state.userName || !state.country || !state.jobTitle)) &&
            !state.isUiPath &&
            !hasAdminUsers &&
            (!state.showActivateAdminUserModalAt ||
              state.showActivateAdminUserModalAt < Date.now());

          dispatch({
            type: 'setShowActivateAdminUserModal',
            payload: shouldShowActivateAdminUserModal,
          });

          dispatch({
            type: 'setCompanyType',
            payload: companyData.companyType,
          });
          dispatch({
            type: 'setCompanyLogo',
            payload: companyData.companyLogo,
          });
          dispatch({
            type: 'setCompanyCountry',
            payload: companyData.companyCountry,
          });
          dispatch({
            type: 'setAccountQuestionnaire',
            payload: companyData.accountQuestionnaire,
          });
          dispatch({
            type: 'setUiPathContacts',
            payload: companyData.uiPathContacts,
          });
          dispatch({
            type: 'setTeamUsers',
            payload: companyData.teamUsers,
          });
          dispatch({
            type: 'setIsHapoEnabled',
            payload: companyData.isHapoEnabled,
          });
          dispatch({
            type: 'setIsUtoEnabled',
            payload: companyData.isUtoEnabled,
          });
          dispatch({
            type: 'setLicenseManagementEnabled',
            payload: companyData.isUtoEnabled || companyData.isHapoEnabled,
          });
          dispatch({
            type: 'setSupportOnlyProvidedByCountry',
            payload: companyData.supportOnlyProvidedByCountry,
          });
        } catch (e) {
          dispatch({
            type: 'setUiPathContacts',
            payload: [],
          });
          dispatch({
            type: 'setTeamUsers',
            payload: [],
          });
          setShowPrimaryBanner(false);
        }
      }
    };

    const updateCompanyData = async () => {
      const accessToken = await getAccessToken();
      if (canViewLicenses) {
        getCompanyLicenses(state.companyId, accessToken);
      }
      getCompanyData(state.companyId, accessToken);
    };

    if (state.companyId && !state.isBlocked) {
      updateCompanyData();
    }
  }, [ state.companyId, state.companyType, state.companyLogo, user, isEmailVerified ]);

  // Listen for changes in user state to see if we should show the verify email page
  // If we do, we also need to hide the header and footer
  useEffect(() => {
    const shouldShowVerifyEmailPage: boolean = (
      authType === AuthType.Classic &&
      isAuthenticated &&
      !!user &&
      !isEmailVerified
    );
    setShowVerifyEmailPage(shouldShowVerifyEmailPage);
    if (
      shouldShowVerifyEmailPage !== state.ui?.header?.hidden ||
      shouldShowVerifyEmailPage !== state.ui?.footer?.hidden
    ) {
      dispatch({
        type: 'setHeaderFooterVisibility',
        payload: {
          header: { hidden: shouldShowVerifyEmailPage },
          footer: { hidden: shouldShowVerifyEmailPage },
        },
      });
    }
  }, [ authType, isAuthenticated, user, isEmailVerified, state.ui?.header?.hidden, state.ui?.footer?.hidden ]);

  const closeStickyMessage = (i: number) => {
    dispatch({
      type: 'removeNotificationBannerMessage',
      payload: i,
    });
  };

  // On the first render of App, we wait until setUser() has finished before rendering page content.
  // On future page changes, we directly continue to rendering, and setUser() will instead run in the background.
  // This allows seamless navigation between pages without flickering or loading screens.
  // When setUser() in the background finishes, the content will re-render (or potentially redirect) using the updated state.
  if (!state.finishedInitialUserCheck) {
    return <></>;
  }

  const showUnregisteredPages: boolean = isEmailVerified && !isRegisteredUser;
  // Ensure originally-unregistered users are redirected to the correct page after they are detected to be registered
  if (showUnregisteredPages && ![ '/' ].includes(location.pathname) && !location.pathname.startsWith(UNREGISTERED_PATH_PREFIX)) {
    localStorage.setItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY, location.pathname + location.search);
  }
  const showAccessDeniedPage: boolean = isEmailVerified && isRegisteredUser && state.isBlocked;
  const showDebugPage: boolean = [ '/debug' ].includes(location.pathname);

  const showRegisteredPages: boolean =
    !state.isBlocked &&
    isRegisteredUser &&
    !!state.userEmail &&
    isEmailVerified;

  const postAccountActivationRedirect = localStorage.getItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY);
  if (showRegisteredPages && postAccountActivationRedirect) {
    localStorage.removeItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY);
    return <Redirect to={postAccountActivationRedirect} />;
  }

  const isRFQEnabled = UserPermissionsHelper.isRFQEnabled();
  const canViewBrowsePage = UserPermissionsHelper.isUiPathUser();
  const canEditProfile = UserPermissionsHelper.isEditProfileAllowed();
  const canEditKB = UserPermissionsHelper.isEditKBAllowed();
  const canViewAnalytics = UserPermissionsHelper.isViewAnalyticsAllowed();
  const canEditSuperAdmins = UserPermissionsHelper.isEditSuperAdminsAllowed();
  const canViewProductCatalog = UserPermissionsHelper.isViewProductCatalogAllowed();
  const canViewNotificationsPage = UserPermissionsHelper.isShowNotificationsAllowed();
  const canMakeHAPOHistoryRequest = UserPermissionsHelper.isCompleteHAPOOrderAllowed();
  const canManageHAPO = UserPermissionsHelper.isManageHAPOAllowed();
  const isCollabSpaceEnabled = UserPermissionsHelper.isCollabSpaceEnabled();

  // Re-store the redirect url if the user pressed continue in
  // the Verify Email page without actually verifying
  const urlWithAllParams = getUrlWithAllParams();
  if (showVerifyEmailPage && urlWithAllParams !== '/verify-email') {
    localStorage.setItem(CP_POST_AUTH_REDIRECT_URL_KEY, urlWithAllParams);
  } else if (!showVerifyEmailPage) {
    localStorage.removeItem(CP_POST_AUTH_REDIRECT_URL_KEY);
  }

  return (
    <>
      {showVerifyEmailPage && (
        <>
          <Switch>
            <PrivateRoute
              exact
              path="/verify-email"
              component={VerifyEmailPage}
            />
            <Redirect to="/verify-email" />
          </Switch>
        </>
      )}

      <ActionPanel />

      <CustomerPortalBanner
        showBanner={showPrimaryBanner}
      />
      <NotificationBanner
        message={state.bannerMsg}
        type={state.bannerType}
        actions={state.bannerActions}
        autoHide={state.bannerAutoHide}
        isCloseEnabled={state.bannerIsCloseEnabled}
      />

      <StickyMessageBanner
        messages={state.notificationBannerMessages ?? []}
        handleCloseMessage={closeStickyMessage}
      />
      {/* Authenticated users that are not registered in our DB are taken to landing page */}
      {showUnregisteredPages && (
        <>
          <Switch>
            <PrivateRoute
              exact
              path={UNREGISTERED_PATH_PREFIX}
              component={CustomerPortalUnregisteredLandingPage}
            />
            <PrivateRoute
              exact
              path={`${UNREGISTERED_PATH_PREFIX}/activate-account`}
              component={ActivateAccountPage}
            />
            <PrivateRoute
              exact
              path={`${UNREGISTERED_PATH_PREFIX}/support/add-case`}
              component={CustomerPortalPageAddCase}
            />
            {/* Users directly accessing /support/add-case but happen to be authenticated are taken directly to the support case form */}
            <Redirect
              from="/support/add-case"
              to={hasPublicForceLoginQueryParam() ? UNREGISTERED_PATH_PREFIX : `${UNREGISTERED_PATH_PREFIX}/support/add-case`}
              exact
            />
            <Redirect
              to={UNREGISTERED_PATH_PREFIX}
            />
          </Switch>
        </>
      )}

      {/* Redirect user to access denied page if the user was disabled */}
      {showAccessDeniedPage && (
        <>
          <Switch>
            <PrivateRoute
              exact
              path="/access_denied"
              component={CustomerPortalPage403}
            />
            <Redirect to="/access_denied" />
          </Switch>
        </>
      )}

      {/* If user authenticated, not blocked/disabled, then show the fully-registered routes */}
      {showRegisteredPages && (
        <>
          {/* If multiple modals can appear, prioritize which modal to show */}
          {state.showGetUserInfoModal ? <GetUserInfoModal /> : (
            state.showActivateAdminUserModal ? <ActivateAdminUserModal /> :
              <AnnouncementModal /> // Determines whether to show itself by checking for announcements matching route
          )}

          <div>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={Home} />
              {showDebugPage && (
                <PrivateRoute
                  exact
                  path="/debug"
                  component={DebugPage} />
              )}
              {canViewBrowsePage && (
                <PrivateRoute
                  exact
                  path="/browse"
                  component={CustomerPortalPageBrowse}
                />
              )}
              <PrivateRoute
                exact
                path="/knowledge-base"
                component={CustomerPortalPageKnowledge}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/category/:name"
                component={CustomerPortalPageCategory}
              />
              <PrivateRoute
                exact
                path="/knowledge-base/category/:name/:type/:kb"
                component={KBSharedLinkComponent}
              />
              {isCollabSpaceEnabled && (
                <PrivateRoute
                  exact
                  path="/collab-space"
                  component={CustomerPortalPageCollab}
                />
              )}
              {isCollabSpaceEnabled && (
                // Path when shared a folder link
                <PrivateRoute
                  exact
                  path="/collab-space/folders/:fileId/:slug?"
                  component={CustomerPortalPageCollab}
                />
              )}
              {isCollabSpaceEnabled && (
                // Path when shared a collab download asset link
                <PrivateRoute
                  exact
                  path="/collab-space/assets/:assetId/:slug?"
                  component={CollabDownload}
                />
              )}
              <PrivateRoute
                exact
                path="/company"
                component={CustomerPortalPageCompany}
              />
              {canViewNotificationsPage && (
                <PrivateRoute
                  exact
                  path="/notifications"
                  component={CustomerPortalPageNotifications}
                />
              )}
              <PrivateRoute
                exact
                path="/support"
                component={CustomerPortalPageSupport}
              />
              <PrivateRoute
                exact
                path="/support/details/:id"
                component={CustomerPortalSupportDetails}
              />
              <PrivateRoute
                exact
                path="/support/file/:fileId/:slug?"
                component={FileDownload}
              />
              <PrivateRoute
                exact
                path="/support/knowledge-base"
                component={CustomerPortalSupportKB}
              />
              <PrivateRoute
                exact
                path="/support/knowledge-base/:product"
                component={CustomerPortalSupportKBCategory}
              />
              <PrivateRoute
                exact
                path="/known-issues"
                component={CustomerPortalKnownIssues}
              />
              <PrivateRoute
                exact
                path="/known-issues/:key"
                component={CustomerPortalKnownIssuesDetails}
              />
              <PrivateRoute
                exact
                path="/kb/:articleNum"
                component={CustomerPortalSupportKBDetails}
              />
              <PrivateRoute
                exact
                path="/support/add-case"
                component={CustomerPortalPageAddCase}
              />
              <PrivateRoute
                exact
                path="/support/premium-care/add-case"
                component={CustomerPortalPageAddPremiumCase}
              />
              {isRFQEnabled && (
                <PrivateRoute
                  exact
                  path="/request-quote"
                  component={RequestForQuote}
                />
              )}
              {isRFQEnabled && (
                <PrivateRoute
                  exact
                  path="/request-quote/checkout"
                  component={Checkout}
                />
              )}
              <PrivateRoute
                exact
                path="/hapo"
                component={HapoShop} />
              <PrivateRoute
                exact
                path="/hapo/request-history"
                component={HAPORequestHistory}
              />
              <PrivateRoute
                exact
                path="/hapo/checkout"
                component={HapoCheckout}
              />

              {canEditProfile && (
                <PrivateRoute
                  exact
                  path="/profile"
                  component={CustomerPortalPageEditProfile}
                />
              )}
              {canEditKB && (
                <PrivateRoute
                  exact
                  path="/admin/knowledge-base/categories"
                  component={CustomerPortalPageAdminKBCategories}
                />
              )}
              {canViewAnalytics && (
                <PrivateRoute
                  exact
                  path="/admin/analytics"
                  component={CustomerPortalPageAnalytics}
                />
              )}
              {canViewAnalytics && (
                <PrivateRoute
                  exact
                  path="/admin/analytics-rfq-hapo"
                  component={CustomerPortalPageRFQHAPOAnalytics}
                />
              )}
              {canViewProductCatalog && (
                <PrivateRoute
                  exact
                  path="/admin/product-catalog"
                  component={CustomerPortalPageProductCatalogManagement}
                />
              )}
              {canEditSuperAdmins && (
                <PrivateRoute
                  exact
                  path="/admin/user-management"
                  component={CustomerPortalPageSuperUserAdmin}
                />
              )}
              {canManageHAPO && (
                <PrivateRoute
                  exact
                  path="/admin/hapo"
                  component={CustomerPortalPageHAPOManagement}
                />
              )}
              {canManageHAPO && (
                <PrivateRoute
                  exact
                  path="/admin/hapo/entitlement"
                  component={CustomerPortalPageHAPOManagementEntitlement}
                />
              )}
              {canMakeHAPOHistoryRequest && (
                <PrivateRoute
                  exact
                  path="/hapo/request-history/add"
                  component={CustomerPortalPageAddHistoryOrder}
                />
              )}
              {canMakeHAPOHistoryRequest && (
                <PrivateRoute
                  exact
                  path="/hapo/request-history/edit"
                  component={CustomerPortalPageEditPastRequest}
                />
              )}
              <PrivateRoute
                exact
                path="/500"
                component={CustomerPortalPage500}
              />
              <PrivateRoute
                exact
                path="/unauthorized"
                component={CustomerPortalPage401}
              />
              <PrivateRoute
                exact
                path="/support-restricted"
                component={RestrictedSupport}
              />
              <PrivateRoute
                exact
                path="/product-downloads"
                component={CPProductDownloads}
              />
              <PrivateRoute
                exact
                path="/search"
                component={CustomerPortalPageSearch}
              />
              {/* Prevent 404 page when already logged-in user accepts invitation link, or is activated on another tab */}
              <Redirect
                from={UNREGISTERED_PATH_PREFIX}
                to="/"
              />
              <Redirect
                from="/access_denied"
                to="/"
              />

              <PrivateRoute
                path="*"
                component={CustomerPortalPage404} />
            </Switch>
          </div>

          {/* Hide footer when UI state requires it to be hidden */}
          {!state.ui?.footer?.hidden && (
            <Footer />
          )}
        </>
      )}
    </>
  );
};

const App = () => {
  useUpdateDetector();
  const showPublicExperience = useShowPublicExperience();

  return (
    showPublicExperience ? (
      <CustomerPortalPublicLandingPage />
    ) : (
      <AuthGuard>
        <Header>
          <Switch>
            <AuthenticatedApp />
          </Switch>
        </Header>
      </AuthGuard>
    )
  );
};

export default App;
