import {
  CaseRecordType,
  FileSizes,
  Header,
  SupportEmailReplyWhiteListedFileExtensions,
} from '@customer-portal/constants';
import MaterialButton from '@mui/material/Button';
import axios from 'axios';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

// styles
import * as styled from '../../assets/css/CustomerPortalSupportDetails';
import Email_Conversation_Icon from '../../assets/img/Email_Conversation_Icon.png';
import heroBg from '../../assets/img/Hero-PlainGrid.png';
import ErrorBlue from '../../assets/img/svg/browse/Error_Blue.svg';
import CloudUp from '../../assets/img/svg/file_icons/Cloud_Up_Black.svg';
import Button from '../../components/Button/Button';
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Google Analytics
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import NotificationsModal from '../../components/CustomerPortal-NotificationsModal';
import ScrollToTop from '../../components/CustomerPortal-ScrollToTop';
import RichTextEditor from '../../components/RichTextEditor';
import StickyMessageBanner from '../../components/StickyMessageBanner';
// components
import CaseActionsButton from '../../components/support/CaseActionsButton';
import CustomerPortalSupportButton from '../../components/support/CustomerPortal-SupportButton';
import PremiumSupportContactModal from '../../components/support/PremiumSupport-Contact-Modal';
// Constants
import { isBasicCaseType } from '../../constants/account.constants';
import { ALLOWED_PRIORITY_HIEREARCHICAL_ESCALATION } from '../../constants/caseActions.constants';
import { QueryDocumentNameEnum } from '../../constants/sfdc.constants';
// Constants
import {
  CASE_OUT_OF_SUPPORT_DECLINED,
  closedCasesFilters,
  ELEVATE_ESCALATION_WINDOW_HOURS,
  FOLLOWUP_CASE_WINDOW_DAYS,
  MAX_EMAIL_BODY_LENGTH,
  REOPEN_CASE_WINDOW_DAYS,
  SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM,
} from '../../constants/support.constants';
import { SUPPORT_DETAILS } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { DropDownItemData } from '../../interfaces/sfdc.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import downloadDocument from '../../lib/customerPortalDownload.utils';
import { encodeUTF8ToBase64 } from '../../lib/encodings.utils';
import { isFileAttachmentSupported } from '../../lib/file.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { featureFlag } from '../../utils/featureFlag';
import CustomerPortalPage401 from '../401';
import CustomerPortalPage404 from '../404';
import RestrictedSupport from '../restrictedSupport';
import Container from './../../components/CustomerPortal-New-Container';
import {
  EMAIL_URL,
  SFDC_URL,
  SUPPORT_FILE_URL,
} from './../../constants/network.constants';
// store
import { StoreContext } from './../../store';
// Utils
import { getLastTrimmableSubstring } from './utils';

let jsonResults: any;

const CustomerPortalSupportDetails = (props: any) => {
  const { t } = useTranslation('common');
  const fileInput = React.createRef<any>();
  const hideBackButton = new URLSearchParams(window.location.search).get(SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.key)
    === SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.value;

  /* Default constants */
  const { id } = useParams<{ id: string }>();
  const history: any = useHistory();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ noResults, setNoResults ] = useState(false);
  const [ subject, setSubject ] = useState('Loading ...');
  const [ description, setDescription ] = useState('');
  const [ recordTypeId, setRecordTypeId ] = useState(CaseRecordType.INCIDENT);
  const [ replies, setReplies ] = useState([]);
  const [ repliesLoading, setRepliesLoading ] = useState(true);
  const [ back, setBack ] = useState(false);
  const [ ticketBy, setTicketBy ] = useState('');
  const [ dateStart, setDateStart ] = useState('');
  const [ dateOpen, setDateOpen ] = useState('');
  const [ caseNumber, setCaseNumber ] = useState('');
  const [ sts, setSts ] = useState('');
  const [ isClosed, setIsClosed ] = useState(false);
  const [ isEscalated, setIsEscalated ] = useState(false);
  const [ isFRViolated, setIsFRViolated ] = useState(false);
  const [ isFRCompleted, setIsFRCompleted ] = useState(false);
  const [ isEscalatedRecently, setIsEscalatedRecently ] = useState(false);
  const [ isAllowedPriorityElevateEscalation, setIsAllowedPriorityElevateEscalation ] = useState(false);
  const [ isClosedDueToOutOfSupport, setIsClosedDueToOutOfSupport ] = useState(false);
  const [ isClosedMoreThan15Days, setIsClosedMoreThan15Days ] = useState(false);
  const [ isClosedMoreThan180Days, setIsClosedMoreThan180Days ] = useState(false);
  const [ allowReopenClosedCase, setAllowReopenClosedCase ] = useState(false);
  const [ followupCaseNumber, setFollowupCaseNumber ] = useState('');
  const [ followupCaseSubject, setFollowupCaseSubject ] = useState('');
  const [ timezone, setTimezone ] = useState('');
  const [ contactNumber, setContactNumber ] = useState('');
  const [ escalationReasons, setEscalationReasons ] = useState<DropDownItemData[]>([]);
  const [ followUpReasons, setFollowUpReasons ] = useState<DropDownItemData[]>([]);
  const [ reopenReasons, setReopenReasons ] = useState<DropDownItemData[]>([]);
  const [ emailTxt, setEmailTxt ] = useState('');
  const [ attachedFiles, setAttachedFiles ] = useState<File[]>([]);
  const [ open, setOpen ] = useState(false); // modal
  const [ isLoading, setIsLoading ] = useState(true);
  const [ supportModalOpen, setSupportModalOpen ] = useState(false);
  const [ shouldReset, setShouldReset ] = useState(false);
  const [ supportCaseNotFound, setSupportCaseNotFound ] = useState(false);
  const [ supportCaseAccessUnauthorized, setSupportCaseAccessUnauthorized ] = useState(false);
  const [ sending, setSending ] = useState(false); // modal
  const [ showBanner, setShowBanner ] = useState(history.location.state?.from === 'CaseCreation');

  const canEditPremiumIncident = UserPermissionsHelper.isEditPremiumSupportAllowed();
  const canViewPremiumIncident = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const canViewIncident = UserPermissionsHelper.isViewSupportAllowed();
  const canEditIncident = UserPermissionsHelper.isEditSupportAllowed();
  const canUserSupportRestrictedAccount = UserPermissionsHelper.canUserSupportRestrictedAccount();
  const [ isElevateEscalationEnabled, setIsElevateEscalationEnabled ] = useState(false);
  const [ canBypassFirstResponseforCaseEscalation, setCanBypassFirstResponseforCaseEscalation ] = useState(false);

  const canViewCaseDetails = () => (
    !isLoading &&
      !supportCaseNotFound &&
      !supportCaseAccessUnauthorized &&
      ((recordTypeId === CaseRecordType.PREMIUM &&
        canViewPremiumIncident &&
        !UserPermissionsHelper.isActivateSuccessCustomer()) ||
        (isBasicCaseType(recordTypeId) && canViewIncident && canUserSupportRestrictedAccount))
  );

  // Allowed mime types
  // Document: .txt, .csv, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .xml
  // Image: .bmp, .gif, .jpeg, .jpg, .png
  // Arhive: .zip, .rar, .7zip
  // Audio: .mp3, .ogg
  // Video: .mpeg, .mpg, .mov
  const SupportedAttachmentFileTypes: Set<string> = new Set([
    'text/plain',
    'text/csv',
    'application/rtf',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/x-citrix-jpeg',
    'image/png',
    'image/x-citrix-png',
    'image/x-png',
    'application/zip',
    'application/x-zip-compressed',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'audio/mp3',
    'audio/ogg',
    'video/mpeg',
    'video/quicktime',
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleError = () => {
    setOpen(false);
  };

  const getCaseActionData = async () => {
    const headers = {
      Authorization: `Bearer ${await getAccessToken()}`,
      [Header.SELECTED_ACCOUNT_ID]: state.companyId,
      [Header.AUTH_TYPE]: getAuthType(),
    };

    try {
      const [
        escalationReasonsResponse,
        followUpReasonsResponse,
        reopenReasonsResponse,
      ] = await Promise.all([
        axios.get(`${SFDC_URL}/getCaseEscalationReasons`, { headers }),
        axios.get(`${SFDC_URL}/getCaseFollowupReasons`, { headers }),
        axios.get(`${SFDC_URL}/getCaseReopenReasons`, { headers }),
      ]);

      if (escalationReasonsResponse?.data?.escalationReasons) {
        setEscalationReasons(escalationReasonsResponse.data.escalationReasons);
      }
      if (followUpReasonsResponse?.data?.followupReasons) {
        setFollowUpReasons(followUpReasonsResponse.data.followupReasons);
      }
      if (reopenReasonsResponse?.data?.reopenReasons) {
        setReopenReasons(reopenReasonsResponse.data.reopenReasons);
      }
    } catch (err) {
      console.log('Failed to get escalation, followup and reopen reasons', err);
    }
  };

  const getTicketReplies = async () => {
    const headers = {
      Authorization: `Bearer ${await getAccessToken()}`,
      [Header.SELECTED_ACCOUNT_ID]: state.companyId,
      [Header.AUTH_TYPE]: getAuthType(),
    };

    try {
      setRepliesLoading(true);
      const caseDetailsResponse = await axios.get(`${SFDC_URL}/getCaseEmailReplies/${id}`, { headers });
      if (caseDetailsResponse?.data?.emailMessages?.records) {
        setReplies(caseDetailsResponse.data.emailMessages.records);
      }
    } catch (err) {
      setReplies([]);
    } finally {
      setRepliesLoading(false);
    }
  };

  const getTicketData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${await getAccessToken()}`,
        [Header.SELECTED_ACCOUNT_ID]: state.companyId,
        [Header.AUTH_TYPE]: getAuthType(),
      };

      const caseDetailsResponse = await axios.get(`${SFDC_URL}/getCaseByTicketId/${id}`, { headers });

      if (!caseDetailsResponse?.data?.data?.caseDetails) {
        setNoResults(true);
      }
      const caseDetails = caseDetailsResponse?.data?.data?.caseDetails;
      const caseAccountDetails = caseDetailsResponse?.data?.data?.caseAccountDetails;
      setCanBypassFirstResponseforCaseEscalation(caseDetailsResponse?.data?.data?.canBypassFirstResponseforCaseEscalation ?? false);

      if (caseDetails) {
        if (caseDetails.AccountId !== state.companyId && caseAccountDetails) {
          // switch company
          dispatch({
            type: 'setCompany',
            payload: {
              companyId: caseAccountDetails.id,
              companyName: caseAccountDetails.name,
              isHapoEnabled: caseAccountDetails.isHapoEnabled,
              isUtoEnabled: caseAccountDetails.isUtoEnabled,
            },
          });
        }
        setRecordTypeId(caseDetails.RecordTypeId || CaseRecordType.INCIDENT);
        setSubject(caseDetails.Subject || t('support_incident_cases_missing_subject_default', '<No Subject>'));
        setDescription(
          caseDetails.Description ||
          caseDetails.Description__c?.replace(/<\/?[^>]+(>|$)/g, '') ||
          ''
        );
        setTicketBy(caseDetails.SuppliedName || 'No Supplied Name');

        if (caseDetails.Start_Date__c) {
          setDateStart(caseDetails.Start_Date__c);
        }
        setDateOpen(caseDetails.CreatedDate || 'No created date');
        setCaseNumber(caseDetails.CaseNumber || 'No case number');
        setSts(caseDetails.Status || 'No status');
        setIsClosed(caseDetails.IsClosed ?? false);
        setIsEscalated(caseDetails.IsEscalated ?? false);
        setIsFRViolated(caseDetails.FR_Voilated__c ?? true);
        setIsFRCompleted(caseDetails.First_Response_Sent__c ?? true);
        setFollowupCaseNumber(caseDetails.FollowupCase?.CaseNumber || '');
        setFollowupCaseSubject(caseDetails.FollowupCase?.Subject || '');
        setTimezone(caseDetails.Time_Zone__c || '');
        setContactNumber(caseDetails.SuppliedPhone || '');
        setIsClosedDueToOutOfSupport(caseDetails.Out_Of_Support_Ticket__c === CASE_OUT_OF_SUPPORT_DECLINED);
        setAllowReopenClosedCase(caseDetails.Allow_Reopen_Closed_Case__c ?? false);

        const checkClosedMoreThan15Days = moment(caseDetails.ClosedDate).isBefore(moment().subtract(REOPEN_CASE_WINDOW_DAYS, 'days'));
        const checkClosedMoreThan180Days = moment(caseDetails.ClosedDate).isBefore(moment().subtract(FOLLOWUP_CASE_WINDOW_DAYS, 'days'));
        setIsClosedMoreThan15Days(checkClosedMoreThan15Days);
        setIsClosedMoreThan180Days(checkClosedMoreThan180Days);

        const checkEscalatedRecently = caseDetails.IsEscalated && moment(caseDetails.EscalatedDate).isAfter(moment().subtract(ELEVATE_ESCALATION_WINDOW_HOURS, 'hours'));
        setIsEscalatedRecently(checkEscalatedRecently);

        const checkAllowedPriorityElevateEscalation = caseDetails.IsEscalated &&
          ALLOWED_PRIORITY_HIEREARCHICAL_ESCALATION.includes(caseDetails.Priority);
        setIsAllowedPriorityElevateEscalation(checkAllowedPriorityElevateEscalation);
      }
    } catch (err) {
      if (err.response?.status === 404) {
        setSupportCaseNotFound(true);
        console.log('Support case not found!');
      } else if (err.response?.status === 401) {
        setSupportCaseAccessUnauthorized(true);
        console.log('Unauthorized to access support case!');
      }
      console.log('loading sfdc was in error', err);
      setNoResults(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCaseActionsEnabled = async () => {
    try {
      const { companyId } = state;

      const isCaseElevateEscalationEnabled = await featureFlag.isElevateEscalationEnabled(
        companyId,
        await getAccessToken()
      );

      setIsElevateEscalationEnabled(isCaseElevateEscalationEnabled);
    } catch (e) {
      setIsElevateEscalationEnabled(false);
    }
  };

  /* Lifecycle */
  useTrackPageViewEvent(SUPPORT_DETAILS);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Support Details');
    getTicketData();
    getCaseActionData();
    getTicketReplies();
    updateCaseActionsEnabled();
  }, []);

  useEffect(() => {
    if (back) {
      history.goBack();
    }
  }, [ back, history ]);

  // user can reply if they have edit permissions and the case is still open
  const canReply = () => {
    const canEdit =
      isBasicCaseType(recordTypeId)
        ? canEditIncident
        : canEditPremiumIncident;

    return canEdit && !closedCasesFilters.includes(sts);
  };

  // user can perform case actions if they have edit permissions for the case
  const canPerformCaseActions = () => isBasicCaseType(recordTypeId)
    ? canEditIncident
    : canEditPremiumIncident;

  const getEmailBody = (txt: string) => {

    const CUTOFF_POINTS = [
      '\n--------------- Original Message ---------------',
      '\n<div>\n<div style="border:none;border-top:solid #E1E1E1 1.0pt',
      '<div class="gmail_quote',
    ];

    let message = txt.replace(
      /<html>|<\/html>|<head>|<\/head>|<title>|<\/title>|<body>|<\/body>|white-space:nowrap/g,
      ''
    );

    // Trim out original messages and line break from thread
    CUTOFF_POINTS.forEach((substr: string) => {
      const idx = message.indexOf(substr);
      if (idx > -1) {
        message = message.slice(0, idx);
      }
    });

    // Parse out any refs from message
    const refStart = 'ref:',
      refEnd = ':ref';
    let refStartIndex = message.indexOf(refStart);
    let refEndIndex = message.indexOf(refEnd);
    while (refStartIndex > -1 && refEndIndex > -1) {
      message =
        message.slice(0, refStartIndex) +
        message.slice(refEndIndex + 1 + refEnd.length);
      refStartIndex = message.indexOf(refStart);
      refEndIndex = message.indexOf(refEnd);
    }

    // Trim off all unnecessary new lines from the message
    let trimmableSubstring = getLastTrimmableSubstring(message);
    while (trimmableSubstring) {
      message = message
        .slice(0, message.lastIndexOf(trimmableSubstring))
        .trim();
      trimmableSubstring = getLastTrimmableSubstring(message);
    }

    // Remove list of attached files with links if any
    message = message.split('<p><br></p><p>{Attached Files}</p>')[0];

    return message;
  };

  const getFile = async (fileId: string) => {
    try {
      await downloadDocument(
        `${SFDC_URL}/file/${fileId}/query/${QueryDocumentNameEnum.ContentDocument}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          },
        }
      );
    } catch (err) {
      const errorMessage = t(
        'support_case_details_attachment_download_error',
        'Error downloading attachment: {{ error_message }}',
        {
          error_message:
            err.response?.data?.data
              ? err.response.data.data
              : err.toString(),
        }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const getLogFile = async (fileUrl: string) => {
    try {
      const fileUrlParts = fileUrl.split('/');
      const fileId = fileUrlParts[fileUrlParts.length - 2];
      await downloadDocument(
        `${SUPPORT_FILE_URL}/${fileId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          },
        }
      );
    } catch (err) {
      const errorMessage = t(
        'support_case_details_attachment_download_error',
        'Error downloading attachment: {{ error_message }}',
        {
          error_message:
            err.response?.data?.data
              ? err.response.data.data
              : err.toString(),
        }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const getAvatar = (nm: string) => {
    if (nm) {
      const tmp = nm.split(' ');
      if (tmp.length > 1) {
        return (
          <styled.Avatar data-testid="Avatar">
            <styled.AvatarInitails data-testid="AvatarInitials">
              {tmp[0][0] + tmp[1][0]}
            </styled.AvatarInitails>
          </styled.Avatar>
        );
      }
      return (
        <styled.Avatar>
          <styled.AvatarInitails>
            {tmp[0][0] + tmp[0][0]}
          </styled.AvatarInitails>
        </styled.Avatar>
      );
    }
    return (
      <styled.Avatar>
        <styled.AvatarInitails>UU</styled.AvatarInitails>
      </styled.Avatar>
    );

  };

  const sendEmail = async () => {
    if (emailTxt.length > MAX_EMAIL_BODY_LENGTH) {
      setOpen(false);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_case_details_send_email_too_long_error',
          `Email not sent. Please reduce the length of the email and try again.`
        ),
      });
      return;
    }

    setSending(true);
    const formData = new FormData();
    attachedFiles.forEach(file => {
      formData.append('files', file);
    });
    formData.set('textBody', encodeUTF8ToBase64(emailTxt));
    formData.set('caseId', id);
    formData.set('subject', subject);
    // customer reply should include the last reply's cc recipients, but only as far as we know
    // if further replies comes in after this customer email, we won't have the updated cc recipients
    const lastReply: any = (replies ?? []).at(0); // last reply is the first in the list
    if (lastReply?.CcAddress) {
      // the last semi-colon is just in case SFDC is appending email addressees to the CC
      formData.set('ccRecipients', lastReply.CcAddress + '; ');
    }

    try {
      jsonResults = await axios.post(
        `${EMAIL_URL}/sendSupportEmail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setSending(false);
      setOpen(false);
      setEmailTxt('');
      setShouldReset(true);
      setAttachedFiles([]);
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_case_details_send_email_success',
          'Email was successfully sent to UiPath Support!'
        ),
      });
      getTicketReplies();
    } catch (err) {
      setOpen(false);
      setSending(false);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_case_details_send_email_error',
          'There was an error in sending an email to UiPath Support. Please try again.'
        ),
      });
    }
  };

  const handleSupportOpen = () => {
    setSupportModalOpen(true);
  };

  const handleSupportClose = () => {
    setSupportModalOpen(false);
  };

  const handleEmailTextChange = (html: string, isEmpty: boolean) => {
    if (isEmpty) {
      setEmailTxt('');
      return;
    }
    setEmailTxt(html);
  };

  const handleFileAttachmentsInputChange = (e: any) => {
    const files: FileList = e.target.files;
    const newFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) {
        return;
      }

      if (file && file.size > FileSizes.TwentyFiveMegabytes) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_form_attachment_size_error',
            'Error uploading document: File exceeds 25MB'
          ),
        });
        return;
      }

      if (
        !isFileAttachmentSupported(file, SupportEmailReplyWhiteListedFileExtensions) ||
        !SupportedAttachmentFileTypes.has(file.type)
      ) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: file.type.length ? t(
            'support_form_attachment_file_type_error',
            'Invalid file type ({{file_type}}) is not allowed! Instead, try zipping the file(s) and then attaching the result.',
            { file_type: file.type }
          ) : t(
            'support_form_attachment_no_file_type_error',
            'This file is not allowed! Instead, try zipping the file(s) and then attaching the result.'
          ),
        });
        return;
      }

      newFiles.push(file);
    }

    setAttachedFiles(newFiles);
  };

  const handleFileAttachmentsInputClick = (e: any) => {
    e.target.value = null;
    setAttachedFiles([]);
  };

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const closeStickyMessage = (i: number) => {
    setShowBanner((prev: boolean) => !prev);
  };

  if (isLoading) {
    return (
      <styled.notificationsLoader>
        <div>
          <CustomerPortalLoader />
        </div>
      </styled.notificationsLoader>
    );
  } else if (supportCaseNotFound) {
    return <CustomerPortalPage404 />;
  } else if (supportCaseAccessUnauthorized) {
    return <CustomerPortalPage401 />;
  } else if (!canUserSupportRestrictedAccount) {
    return <RestrictedSupport />;
  } else if (canViewCaseDetails()) {
    return (
      <>
        <ScrollToTop />
        <Helmet>
          <title>
            {t(
              'support_case_details_page_title',
              'Support Case Details | Customer Portal'
            )}
          </title>
        </Helmet>

        <styled.PageWrapper>
          <NotificationsModal
            onClose={handleClose}
            onError={handleError}
            open={open}
          />
          <PremiumSupportContactModal
            open={supportModalOpen}
            onClose={handleSupportClose}
          />
          {showBanner && (
            <StickyMessageBanner
              messages={[
                {
                  type: 'info',
                  text: t('support_case_details_banner'),
                },
              ]}
              handleCloseMessage={closeStickyMessage}
            />
          )}
          <styled.HomeSection>
            <Container cssClass="CustomerPortalPage__container">
              {
                !hideBackButton && (
                  <div
                    className="heroBackButton"
                    onClick={() => {
                      setBack(true);
                    }}
                  >
                    {t('support_case_details_go_back_btn', 'Go Back')}
                  </div>
                )
              }
              <styled.heroTitleWrapper hideBackButton={hideBackButton}>
                <styled.heroTitle data-testid="CaseTitle">
                  {subject}
                </styled.heroTitle>

                {recordTypeId === CaseRecordType.PREMIUM && (
                  <CustomerPortalSupportButton
                    onClick={handleSupportOpen}
                    disabled={!canEditPremiumIncident}
                  >
                    {t(
                      'support_case_details_premium_support_btn',
                      'Premium Support'
                    )}
                  </CustomerPortalSupportButton>
                )}

                <CaseActionsButton
                  disabled={!canPerformCaseActions()}
                  id={id}
                  caseNumber={caseNumber}
                  isClosed={isClosed}
                  isEscalated={isEscalated}
                  isFRViolated={isFRViolated}
                  isFRCompleted={isFRCompleted}
                  setIsEscalated={setIsEscalated}
                  escalationReasons={escalationReasons}
                  followUpReasons={followUpReasons}
                  reopenReasons={reopenReasons}
                  isClosedDueToOutOfSupport={isClosedDueToOutOfSupport}
                  isClosedMoreThan15Days={isClosedMoreThan15Days}
                  isClosedMoreThan180Days={isClosedMoreThan180Days}
                  isEscalatedRecently={isEscalatedRecently}
                  isAllowedPriorityElevateEscalation={isAllowedPriorityElevateEscalation}
                  allowReopenClosedCase={allowReopenClosedCase}
                  followupCaseNumber={followupCaseNumber}
                  followupCaseSubject={followupCaseSubject}
                  timezone={timezone}
                  contactNumber={contactNumber}
                  setFollowupCaseNumber={setFollowupCaseNumber}
                  isElevateEscalationEnabled={isElevateEscalationEnabled}
                  canBypassFirstResponseforCaseEscalation={canBypassFirstResponseforCaseEscalation}
                />
              </styled.heroTitleWrapper>
              <img
                src={heroBg}
                className="fullWidthHero"
                alt="Support Details Hero"
              />
              {!noResults && (
                <div>
                  <styled.supportDetailsBanner>
                    <styled.supportDetailsBannerName
                      isPremium={recordTypeId === CaseRecordType.PREMIUM}
                      data-testid="UserName"
                    >
                      {getAvatar(ticketBy)}
                      {' '}
                      {ticketBy ? ticketBy : 'Unknown User'}
                    </styled.supportDetailsBannerName>
                    <styled.supportDetailsBannerRequestDate
                      isPremium={recordTypeId === CaseRecordType.PREMIUM}
                    >
                      <styled.supportDetailsBannerLabel>
                        {t(
                          'support_premium_support_cases_column_request_start_date',
                          'Request Start Date'
                        )}
                        :
                      </styled.supportDetailsBannerLabel>
                      <Moment
                        data-testid="RequestDate"
                        format="MMM DD YYYY">
                        {dateStart}
                      </Moment>
                    </styled.supportDetailsBannerRequestDate>
                    <styled.supportDetailsBannerDateCase>
                      <styled.supportDetailsBannerLabel data-testid="CreatedDate">
                        {t(
                          'support_incident_cases_column_created_on',
                          'Created On'
                        )}
                        :
                      </styled.supportDetailsBannerLabel>
                      <Moment format="MMM DD YYYY hh:mm A">{dateOpen}</Moment>
                    </styled.supportDetailsBannerDateCase>
                    <styled.supportDetailsBannerCaseNumber data-testid="CaseId">
                      <styled.supportDetailsBannerLabel>
                        {t('support_incident_cases_column_case_no', 'Case #')}
:
                      </styled.supportDetailsBannerLabel>
                      {caseNumber}
                    </styled.supportDetailsBannerCaseNumber>
                    <styled.supportDetailsBannerStatus data-testid="CaseStatus">
                      {closedCasesFilters.includes(sts) ? (
                        <styled.ClosedTicket>Closed</styled.ClosedTicket>
                      ) : (
                        <styled.OpenTicket>{sts}</styled.OpenTicket>
                      )}
                    </styled.supportDetailsBannerStatus>
                  </styled.supportDetailsBanner>

                  {description && (
                    <styled.supportTicketDescriptionWrapper data-testid="Description">
                      <styled.supportDetailsBannerLabel>
                        {t('support_case_details_description', 'Description')}
                      </styled.supportDetailsBannerLabel>
                      {description}
                    </styled.supportTicketDescriptionWrapper>
                  )}

                  <styled.supportEmailsHeader>
                    <img
                      src={Email_Conversation_Icon}
                      className="support_conversation_icon"
                      alt="Email Conversation"
                    />
                    {' '}
                    {t(
                      'support_case_details_email_conversation',
                      'Email Conversation'
                    )}
                  </styled.supportEmailsHeader>

                  {canReply() && (
                    <styled.sendEmailContainer data-testid="SendEmailContainer">
                      <div style={{ display: 'flex' }}>
                        <styled.AvatarContainer>
                          {getAvatar(state.userName)}
                        </styled.AvatarContainer>
                        <div style={{ width: '100%' }}>
                          <RichTextEditor
                            handleOnChange={handleEmailTextChange}
                            placeholder={t(
                              'support_case_details_rich_text_placeholder',
                              'Type here and click send to send an email to UiPath Support'
                            )}
                            defaultValue={emailTxt}
                            stripPastedStyles
                            shouldReset={shouldReset}
                            setShouldReset={setShouldReset}
                          />
                        </div>
                      </div>

                      <div className="SendEmailContainer__Case-Field--Upload">
                        <styled.SupportCaseUploadedFileInfo>
                          {attachedFiles.map((file, i) => (
                            <div className="SendEmailContainer__Case-Field--Upload-File-Name">
                              <img
                                src={ErrorBlue}
                                alt="Cloud up icon"
                                onClick={() => {
                                  setAttachedFiles([
                                    ...attachedFiles.slice(0, i),
                                    ...attachedFiles.slice(i + 1),
                                  ]);
                                }}
                              />
                              {file.name}
                            </div>
                          ))}
                        </styled.SupportCaseUploadedFileInfo>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="SendEmailContainer__Case-Field--Upload">
                          <>
                            <MaterialButton
                              variant="outlined"
                              className="SendEmailContainer__Case-Field--Upload-Button"
                              disableElevation
                              onClick={handleUploadButtonClick}
                              startIcon={
                                <img
                                  src={CloudUp}
                                  alt="Cloud up icon" />
                              }
                            >
                              {t(
                                'support_case_details_attach_file_label',
                                'Attach Files'
                              )}
                            </MaterialButton>

                            <form
                              id="case_attachment"
                              className="SendEmailContainer__Case-Field--Upload-Form"
                            >
                              <input
                                className="CustomerPortalUploadForm__Input"
                                name="attachment"
                                type="file"
                                multiple
                                onChange={handleFileAttachmentsInputChange}
                                onClick={handleFileAttachmentsInputClick}
                                ref={fileInput}
                                data-testid="file-input"
                              />
                            </form>
                          </>
                        </div>

                        <Button
                          isLoading={sending}
                          text={t(
                            'support_case_details_send_email_btn',
                            'Send'
                          )}
                          className="supportButton"
                          onClick={() => {
                            sendEmail();
                          }}
                          disabled={emailTxt.length < 1}
                          isValid={emailTxt.length > 0}
                        />
                      </div>
                    </styled.sendEmailContainer>
                  )}

                  <ul
                    className="SupportEmails__EmailList"
                    data-testid="EmailList"
                  >
                    {repliesLoading && (
                      <styled.notificationsLoader>
                        <div>
                          <CustomerPortalLoader />
                        </div>
                      </styled.notificationsLoader>
                    )}
                    {!repliesLoading && replies.map((email: any) => (
                      <li
                        key={email.MessageDate}
                        data-testid="EmailList_Item">
                        <styled.supportEmailsListContainer>
                          <styled.supportPersonDetails>
                            <styled.supportEmailDate>
                              {t(
                                'support_case_details_email_list_sent_on',
                                'Sent on'
                              )}
                              :
                              {' '}
                              <Moment format="MMM DD YYYY HH:mm A">
                                {email.MessageDate}
                              </Moment>
                            </styled.supportEmailDate>

                            <styled.supportTicketPerson>
                              From:&nbsp;
                              {email.FromName || email.FromAddress}
                            </styled.supportTicketPerson>
                            {email.ToAddress &&
                            <styled.supportTicketPerson>
                              To:&nbsp;
                              {email.ToAddress}
                            </styled.supportTicketPerson>}
                            {email.CcAddress &&
                            <styled.supportTicketPerson>
                              Cc:&nbsp;
                              {email.CcAddress}
                            </styled.supportTicketPerson>}
                          </styled.supportPersonDetails>
                          <styled.supportEmailsTextBody>
                            {email.HtmlBody ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: getEmailBody(email.HtmlBody) }}
                                style={{ width: 'inherit !important' }}
                              />
                            ) : (
                              getEmailBody(email.TextBody)
                            )}

                            <styled.SupportCaseUploadedFileInfo>
                              {email.CombinedAttachments?.records?.map(
                                (file: any, idx: number) => (
                                  <React.Fragment key={idx}>
                                    <br />
                                    <styled.EmailReplyFileInfo
                                      onClick={() => getFile(file.Id)}
                                    >
                                      {file.Title.endsWith(file.FileExtension) ? file.Title : `${file.Title} (${file.FileExtension})`}
                                    </styled.EmailReplyFileInfo>
                                  </React.Fragment>
                                )
                              )}
                            </styled.SupportCaseUploadedFileInfo>
                            <styled.SupportCaseUploadedFileInfo>
                              {email.attachments?.map(
                                (file: any, idx: number) => (
                                  <React.Fragment key={idx}>
                                    <br />
                                    <styled.EmailReplyFileInfo
                                      onClick={() => getLogFile(file.Attachment_URl__c)}
                                    >
                                      {file.Attachment_name__c}
                                    </styled.EmailReplyFileInfo>
                                  </React.Fragment>
                                )
                              )}
                            </styled.SupportCaseUploadedFileInfo>
                          </styled.supportEmailsTextBody>
                        </styled.supportEmailsListContainer>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {noResults && (
                <styled.noResultsTest data-testid="noResultsTest">
                  {t(
                    'support_case_details_no_results',
                    'We could not find case details'
                  )}
                  <br />
                  <br />
                  <Button
                    text="Back"
                    onClick={() => setBack(true)} />
                </styled.noResultsTest>
              )}
            </Container>
          </styled.HomeSection>
        </styled.PageWrapper>
      </>
    );
  }
  return <CustomerPortalPage401 />;
};

export default CustomerPortalSupportDetails;
