import Select from '@mui/material/Select';
import styled from 'styled-components';

import Button from '../../../components/Button/Button';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const JSONView = styled.pre`
  font-size: 2em;
  width: 50%;
`;

export const DebugPageView = styled.div`
  margin: 16px;
  ${ContentWrapper}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled(Button)`
  min-width: 50%;
  margin: 8px;
  align-self: left;
`;

export const JobSelect = styled(Select)`
  min-width: 30%;
`;
