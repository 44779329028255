import { FeatureFlags } from '@customer-portal/constants';

import {
  axiosGet,
  axiosPatch,
  axiosPublicGet,
} from '../client/axios';
import {
  FEATURE_FLAG_URL,
  PUBLIC_FEATURE_FLAG_URL,
} from '../constants/network.constants';

class FeatureFlag {
  /**
   * Checks if a feature flag is enabled.
   * If companyId or accessToken are undefined, the public endpoint for feature flags will be used.
   * Unless this is being used on a public page, companyId and accessToken should always be passed in.
   */
  async isFeatureEnabled(
    featureKey: string,
    companyId?: string,
    accessToken?: string,
    checkAccounts?: boolean,
  ): Promise<boolean> {
    try {
      let result;
      if (companyId === undefined || accessToken === undefined) {
        result = await axiosPublicGet(
          `${PUBLIC_FEATURE_FLAG_URL}/${featureKey}`,
        );
      } else {
        result = await axiosGet(
          `${FEATURE_FLAG_URL}/${featureKey}`,
          companyId,
          accessToken,
        );
      }
      return !!(result?.data?.enabled) && (!checkAccounts || result?.data?.accounts?.includes(companyId));
    } catch (e) {
      return false;
    }
  }

  async toggleFeatureFlag(featureKey: string, companyId: string, accessToken: string) {
    const result = await axiosPatch(
      `${FEATURE_FLAG_URL}/${featureKey}`,
      companyId,
      accessToken,
    );

    return result?.data?.enabled ?? this.isFeatureEnabled(featureKey, companyId, accessToken);
  }

  /**
   * For chatbot, parameters are optional as the public support page needs to check if the feature flag is enabled.
   * If companyId or accessToken are undefined, the public endpoint for feature flags will be used.
   */
  isChatbotEnabled(companyId?: string, accessToken?: string) {
    return this.isFeatureEnabled(FeatureFlags.CHATBOT_ENABLED, companyId, accessToken);
  }

  isElevateEscalationEnabled(companyId: string, accessToken: string) {
    return this.isFeatureEnabled(FeatureFlags.ELEVATE_ESCALATION_ENABLED, companyId, accessToken, true);
  }

  isDocsAISearchEnabled(companyId: string, accessToken: string) {
    return this.isFeatureEnabled(FeatureFlags.DOCSAI_SEARCH_ENABLED, companyId, accessToken);
  }

  isKnownIssuesEnabled(companyId: string, accessToken: string) {
    return this.isFeatureEnabled(FeatureFlags.KNOWN_ISSUES_ENABLED, companyId, accessToken);
  }

  isDocsAIRegenerateResponseEnabled(companyId: string, accessToken: string) {
    return this.isFeatureEnabled(FeatureFlags.DOCSAI_REGENERATE_RESPONSE_ENABLED, companyId, accessToken);
  }
}

export const featureFlag = new FeatureFlag();
