import {
  FormControl,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// Styles
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';

// Styles
import { ModalContentFormWrapper } from '../../../assets/css/Knowledge/CategoryManager-Table';
import { canShowLicense } from '../../../lib/requestForQuote.utils';
import type { SkuTypeTransformedAdmin } from '../../../types/requestForQuote.types';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import RichTextEditor from '../../RichTextEditor';
import TextLabel from '../../TextLabel';

type EditModalProps = {
  sku: SkuTypeTransformedAdmin;
  submitting: boolean;
  handleFormSubmit: (sku: SkuTypeTransformedAdmin) => void;
  handleClose: () => void;
  [key: string]: any;
};

const EditModal = ({
  sku, submitting, handleFormSubmit, handleClose,
}: EditModalProps) => {
  const [ name, setName ] = useState(sku.sku_name?.toString() ?? '');
  const [ productCode, setProductCode ] = useState(
    sku.product_code?.toString() ?? ''
  );
  const [ feature, setFeature ] = useState(sku.tag?.toString() ?? '');
  const [ longDescription, setLongDescription ] = useState(
    sku.long_description?.toString() ?? ''
  );
  const [ minQuantity, setMinQuantity ] = useState(
    sku.min_quantity?.toString() ?? ''
  );
  const [ maxQuantity, setMaxQuantity ] = useState(
    sku.max_quantity?.toString() ?? ''
  );
  const [ nameHelperText, setNameHelperText ] = useState('');
  const [ productCodeHelperText, setProductCodeHelperText ] = useState('');
  const [ featureHelperText, setFeatureHelperText ] = useState('');
  const [ minQuantityHelpText, setMinQuantityHelpText ] = useState('');
  const [ maxQuantityHelpText, setMaxQuantityHelpText ] = useState('');
  const [ shortDescription, setShortDescription ] = useState(
    sku.short_description
  );
  const [ showInRFQ, setShowInRFQ ] = useState(Boolean(sku.show_in?.rfq));
  const [ showInHAPO, setShowInHAPO ] = useState(Boolean(sku.show_in?.hapo));
  const [ showHapo, setShowHapo ] = useState({
    Basic: canShowLicense('Basic', sku.display_hapo),
    Standard: canShowLicense('Standard', sku.display_hapo),
    NonProduction: canShowLicense('NonProduction', sku.display_hapo),
    'CRPA Orchestrator': canShowLicense('CRPA Orchestrator', sku.display_hapo),
    'No Orchestrator': canShowLicense('No Orchestrator', sku.display_hapo),
    Cloud: canShowLicense('Cloud', sku.display_hapo),
    ProcessMining: canShowLicense('ProcessMining', sku.display_hapo),
    'Automation Suite': canShowLicense('Automation Suite', sku.display_hapo),
  });
  const [ showRfq, setShowRfq ] = useState({
    Basic: canShowLicense('Basic', sku.display),
    Standard: canShowLicense('Standard', sku.display),
    NonProduction: canShowLicense('NonProduction', sku.display),
    'CRPA Orchestrator': canShowLicense('CRPA Orchestrator', sku.display),
    'No Orchestrator': canShowLicense('No Orchestrator', sku.display),
    Cloud: canShowLicense('Cloud', sku.display),
    ProcessMining: canShowLicense('ProcessMining', sku.display),
    'Automation Suite': canShowLicense('Automation Suite', sku.display),
  });

  const [ formError, setFormError ] = useState('');

  const rfqDisplaySettings = [
    {
      checkedValue: showRfq.Basic,
      name: 'Basic',
      label: 'Show in Basic Orchestrator',
    },
    {
      checkedValue: showRfq.Standard,
      name: 'Standard',
      label: 'Show in Standard Orchestrator',
    },
    {
      checkedValue: showRfq['CRPA Orchestrator'],
      name: 'CRPA Orchestrator',
      label: 'Show in CRPA Orchestrator',
    },
    {
      checkedValue: showRfq.NonProduction,
      name: 'NonProduction',
      label: 'Show in Non Production Orchestrator',
    },
    {
      checkedValue: showRfq['No Orchestrator'],
      name: 'No Orchestrator',
      label: 'Show in Standalone Orchestrator',
    },
    {
      checkedValue: showRfq.Cloud,
      name: 'Cloud',
      label: 'Show in Cloud',
    },
    {
      checkedValue: showRfq['Automation Suite'],
      name: 'Automation Suite',
      label: 'Show in Automation Suite',
    },
  ];

  const hapoDisplaySettings = [
    {
      checkedValue: showHapo.Basic,
      name: 'Basic',
      label: 'Show in Basic Orchestrator',
    },
    {
      checkedValue: showHapo.Standard,
      name: 'Standard',
      label: 'Show in Standard Orchestrator',
    },
    {
      checkedValue: showHapo['CRPA Orchestrator'],
      name: 'CRPA Orchestrator',
      label: 'Show in CRPA Orchestrator',
    },
    {
      checkedValue: showHapo.NonProduction,
      name: 'NonProduction',
      label: 'Show in Non Production Orchestrator',
    },
    {
      checkedValue: showHapo['No Orchestrator'],
      name: 'No Orchestrator',
      label: 'Show in Standalone Orchestrator',
    },
    {
      checkedValue: showHapo.Cloud,
      name: 'Cloud',
      label: 'Show in Cloud',
    },
    {
      checkedValue: showHapo.ProcessMining,
      name: 'ProcessMining',
      label: 'Show in Process Mining',
    },
    {
      checkedValue: showHapo['Automation Suite'],
      name: 'Automation Suite',
      label: 'Show in Automation Suite',
    },
  ];

  /* Event */

  const handleQuantityChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('');
      return;
    }

    const num = Number(e.target.value);

    if (!Number.isInteger(num) || num < 0) {
      dispatchHelperText(
        'Enter a positive whole number, 0, or leave the field empty'
      );
      return;
    }

    dispatchState(num);
    dispatchHelperText('');
  };

  const handleNameChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('SKU name is required!');
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleProductCodeChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('Product code is required!');
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleFeatureChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allowedFeatureValues = [
      'Orchestrator',
      'Robots',
      'Multiuser',
      'Users',
      'Platform Capabilities',
    ];
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('Feature is required!');
      return;
    }

    if (!allowedFeatureValues.includes(e.target.value)) {
      dispatchState(e.target.value);
      dispatchHelperText(
        `Please enter one of this values: ${allowedFeatureValues.join(', ')}`
      );
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleLongDescriptionChange = (e: any) => {
    if (e.target.value === '') {
      setLongDescription('');
      return;
    }
    setLongDescription(e.target.value);
  };

  const handleShortDescriptionChange = (html: string, isEmpty: boolean) => {
    if (isEmpty) {
      setShortDescription('');
      return;
    }
    setShortDescription(html);
  };

  const handleSubmitClick = (event: React.FormEvent<HTMLFormElement>) => {
    if (
      name === '' ||
      productCode === '' ||
      longDescription === '' ||
      feature === '' ||
      minQuantity === '' ||
      maxQuantity === ''
    ) {
      event.preventDefault();
      setFormError('Please fill the required fields, marked with * !');
      return;
    }

    // Min Max Validation
    if (
      minQuantity &&
      maxQuantity &&
      Number(minQuantity) > Number(maxQuantity)
    ) {
      event.preventDefault();
      setFormError('Minimum Quantity must be less than Maximum Quantity');
      return;
    } else if (
      minQuantity &&
      maxQuantity &&
      Number(maxQuantity) < Number(minQuantity)
    ) {
      event.preventDefault();
      setFormError('Maximum Quantity must be greater than Maximum Quantity');
      return;
    }

    setFormError('');
  };

  const handleHapoDisplayChange = (displayKeyName: string, value: boolean) => {
    setShowHapo({
      ...showHapo,
      [displayKeyName]: value,
    });
  };

  const handleRfqDisplayChange = (displayKeyName: string, value: boolean) => {
    setShowRfq({
      ...showRfq,
      [displayKeyName]: value,
    });
  };

  const handleFormSubmitLocal = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Convert to min and max to numbers

    const temp: SkuTypeTransformedAdmin = {
      ...sku,
      sku_name: name,
      product_code: productCode,
      long_description: longDescription,
      short_description: shortDescription,
      tag: feature as any,
      min_quantity: Number.isNaN(parseInt(minQuantity, 10))
        ? undefined
        : parseInt(minQuantity, 10),
      max_quantity: Number.isNaN(parseInt(maxQuantity, 10))
        ? undefined
        : parseInt(maxQuantity, 10),
      showRfq,
      show_in: {
        rfq: showInRFQ,
        hapo: showInHAPO,
      },
      showHapo,
    };

    handleFormSubmit(temp);
  };

  return (
    <Modal
      modalTitle="Edit"
      modalHeading={`Edit "${sku.sku_name}"`}
      modalDescription="Modal for editing sku"
      open
      handleClose={handleClose}
      testId="AdminEditModal"
    >
      <ModalContentFormWrapper
        className="CustomerPortalProductCatalog__formWrapper"
        onSubmit={formError === '' ? handleFormSubmitLocal : () => { }}
        data-testid="AdminEditModalForm"
      >
        <p style={{
          color: '#526069',
          fontSize: '14px',
        }}>
          <b>Display Settings for RFQ:</b>
        </p>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInRFQ}
                onChange={_event => setShowInRFQ(!showInRFQ)}
                name="rfq"
              />
            }
            label="Available in Request For Quote"
          />
        </div>

        {rfqDisplaySettings.map(
          ({
            checkedValue, name: settingName, label,
          }) => (
            <FormControlLabel
              key={`RfqDisplay_${settingName}`}
              control={
                <Checkbox
                  checked={checkedValue}
                  onChange={(_e, checked) =>
                    handleRfqDisplayChange(settingName, checked)}
                  name={settingName}
                />
              }
              label={label}
            />
          )
        )}

        <br />

        <p style={{
          color: '#526069',
          fontSize: '14px',
        }}>
          <b>Display Settings for Enterprise Agreement:</b>
        </p>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInHAPO}
                onChange={event => setShowInHAPO(!showInHAPO)}
                name="Hapo"
              />
            }
            label="Available in HAPO"
          />
        </div>

        {hapoDisplaySettings.map(
          ({
            checkedValue, name: settingName, label,
          }) => (
            <FormControlLabel
              key={`HapoDisplay_${settingName}`}
              control={
                <Checkbox
                  checked={checkedValue}
                  onChange={(e, checked) =>
                    handleHapoDisplayChange(settingName, checked)}
                  name={settingName}
                />
              }
              label={label}
            />
          )
        )}

        <div
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="SKU Product Code"
              required
            />
            <TextField
              id="SKUProductCode"
              type="string"
              placeholder="Enter a SKU product code"
              value={productCode}
              onChange={handleProductCodeChange(
                setProductCode,
                setProductCodeHelperText
              )}
              helperText={productCodeHelperText}
              aria-label="sku-productCode"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="ProductCode__TextField"
            />
          </FormControl>

          <FormControl style={{ width: '50%' }}>
            <TextLabel
              label="SKU Name"
              required
            />
            <TextField
              id="SKUName"
              type="string"
              placeholder="Enter a SKU name"
              value={name}
              onChange={handleNameChange(setName, setNameHelperText)}
              helperText={nameHelperText}
              aria-label="sku-name"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="SKUName__TextField"
            />
          </FormControl>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="Long description"
              required
            />
            <textarea
              style={{
                resize: 'none',
                fontSize: '14px',
              }}
              onChange={handleLongDescriptionChange}
              placeholder="Enter a long description"
              rows={6}
              cols={42}
              value={longDescription}
            />
          </FormControl>

          <div style={{ width: '50%' }}>
            <RichTextEditor
              handleOnChange={handleShortDescriptionChange}
              label="Short Description"
              placeholder="Enter a short description"
              defaultValue={sku.short_description}
              stripPastedStyles
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '30%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="Feature"
              required
            />
            <TextField
              id="feature"
              type="string"
              value={feature}
              onChange={handleFeatureChange(setFeature, setFeatureHelperText)}
              placeholder="Enter a feature name"
              helperText={featureHelperText}
              aria-label="feature"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              style={{ width: '100%' }}
              data-testid="FeatureName__TextField"
            />
          </FormControl>

          <FormControl style={{
            width: '30%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="Minimum Quantity"
              required
            />
            <TextField
              id="MinQuantity"
              type="number"
              value={minQuantity}
              onChange={handleQuantityChange(
                setMinQuantity,
                setMinQuantityHelpText
              )}
              placeholder="Enter a minimum quantity"
              helperText={minQuantityHelpText}
              aria-label="min-quantity"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              style={{ width: '100%' }}
              data-testid="MinQuantity__TextField"
            />
          </FormControl>

          <FormControl style={{ width: '30%' }}>
            <TextLabel
              label="Maximum Quantity"
              required
            />
            <TextField
              id="MaxQuantity"
              type="number"
              value={maxQuantity}
              onChange={handleQuantityChange(
                setMaxQuantity,
                setMaxQuantityHelpText
              )}
              placeholder="Enter a maximum quantity"
              helperText={maxQuantityHelpText}
              aria-label="max-quantity"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              style={{ width: '100%' }}
              data-testid="MaxQuantity__TextField"
            />
          </FormControl>
        </div>

        <p className="KnowledgeCategories__FormError">
          {formError}
        </p>
        <Button
          className="KnowledgeCategories__SubmitButton"
          onClick={handleSubmitClick}
          isLoading={submitting}
          text="Submit"
        />
      </ModalContentFormWrapper>
    </Modal>
  );
};

export default EditModal;
