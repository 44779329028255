import styled from 'styled-components';

import Stars_24 from '../img/svg/action_icons/Stars_24.svg';
import closeIcon from './../img/svg/browse/Error.svg';
import favouriteIcon from './../img/svg/browse/Favorite.svg';
import favouriteIconFill from './../img/svg/browse/Favorite_Fill.svg';
// Assets
import searchIcon from './../img/svg/browse/search_blue.svg';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const ClientsSelection = styled.section`
  position: relative;
  background-color: #f5f5f6;
  ${ContentWrapper}

  .CustomerPortalClientsSelection__container {
    display: flex;
    flex-direction: column;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: space-between;
    }

    .CustomerPortalClientsSelection__message {
      width: 100%;
      padding: ${p => p.theme.spacing(8)}px;
      text-align: center;

      h2 {
        margin: ${p => p.theme.spacing(6)}px;
        font-size: 24px;
      }
    }

    .CustomerPortalClientsSelection__notFound {
      width: 100%;
      padding: ${p => p.theme.spacing(8)}px 0;
      text-align: left;

      h2 {
        font-size: 16px;
      }
    }
  }
`;

export const Sidebar = styled.aside`
  width: 100%;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 25%;
  }
`;

export const Filter = styled.div`
  .CustomerPortalClientsSelection__filterTitle {
    font-size: 2rem;
    font-weight: normal;
    color: #3c4554;
    margin-bottom: 16px;
  }

  .CustomerPortalClientsSelection__filterItems {
    margin-top: ${p => p.theme.spacing(2)}px;
  }

  .CustomerPortalClientsSelection__filterItemName {
    padding-left: ${p => p.theme.spacing(2)}px;
  }
`;

interface FilterItemProps {
  active?: Boolean;
  disable?: Boolean;
}

export const FilterItem = styled.div<FilterItemProps>`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: ${p => (p.disable ? 'default' : 'pointer')};
  color: ${p =>
    p.disable
      ? p.theme.palette.grey[300]
      : p.active
        ? 'white'
        : 'initial'};

  background-color: ${p =>
    p.active ? p.theme.palette.blue[500] : 'white'};
  border-radius: ${p => p.theme.spacing(1)}px;

  display: flex;
  flex-direction: row;

  font-size: 1.4rem;
  font-weight: normal;

  transition: all 0.3s ease;

  &:hover {
    color: ${p =>
    p.disable
      ? p.theme.palette.grey[300]
      : p.active
        ? 'white'
        : p.theme.palette.blue[500]};

    transition: all 0.3s ease;
  }
`;

export const ClientsContent = styled.section`
  width: 100%;

  ${p => p.theme.breakpoints.up('sm')} {
    flex: 1 0 auto;
    width: calc(75% - ${p => p.theme.spacing(3)}px);
  }

  .CustomerPortalClientsSelection__clientsHeader {
    display: flex;
    flex-direction: column;
    ${p => p.theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: space-between;
      margin-left: ${p => p.theme.spacing(5)}px;
    }

    ${p => p.theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  }

  .CustomerPortalClientsSelection__clientsActions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    min-height: 90px;

    ${p => p.theme.breakpoints.up('sm')} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
    }
  }

  .CustomerPortalClientsSelection__clientsSort {
    min-width: 180px;
  }

  .CustomerPortalClientsSelection__TextWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .CustomerPortalClientsSelection__clientsSearch {
    margin-left: 16px;
    padding: ${p => p.theme.spacing(1.25)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    border-radius: ${p => p.theme.spacing(1)}px;
  }

  .CustomerPortalClientsSelection__clientsSearchIcon {
    position: relative;
    width: ${p => p.theme.spacing(2.5)}px;
    height: ${p => p.theme.spacing(2.5)}px;
    cursor: pointer;

    &:before {
      content: '';
      background: url(${searchIcon}) no-repeat;

      position: absolute;
      top: 5px;
      left: 4px;

      width: ${p => p.theme.spacing(2.5)}px;
      height: ${p => p.theme.spacing(2.5)}px;
    }

    &--close {
      &:before {
        top: 2px;
        background: url(${closeIcon}) no-repeat;
      }
    }
  }

  .CustomerPortalClientsSelection__clientsSearchInput {
    display: none;

    &--active {
      display: block;
    }

    input {
      width: auto;

      font-size: 1.4rem;

      outline: none;
      border: none;

      ${p => p.theme.breakpoints.up('sm')} {
        width: ${p => p.theme.spacing(25)}px;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #353c4a;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #353c4a;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #353c4a;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #353c4a;
      }
    }
  }

  .CustomerPortalClientsSelection__addNewTicketBtn {
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    border-radius: ${p => p.theme.spacing(1)}px;
    outline: none;
    text-decoration: none;

    ${p => p.theme.breakpoints.up('sm')} {
      margin-left: ${p => p.theme.spacing(2)}px;
    }
  }

  .CustomerPortalClientsSelection__clientsLeadText {
    color: #3c4554;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 36px;

    ${p => p.theme.breakpoints.up('md')} {
      font-size: 2.4rem;
    }
  }

  .CustomerPortalClientsSelection__clientsList {
    margin-top: ${p => p.theme.spacing(4)}px;
    padding-left: 0;
    list-style: none;

    ${p => p.theme.breakpoints.up('sm')} {
      padding-left: ${p => p.theme.spacing(5)}px;
    }
  }

  .CustomerPortalClientsSelection__clientsListItem {
    position: relative;
    margin-bottom: ${p => p.theme.spacing(3)}px;
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    border-radius: ${p => p.theme.spacing(1)}px;

    height: 100%;

    cursor: pointer;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4)}px;
    }

    &.CustomerPortalSupport--premiumListItem {
      ${p => p.theme.breakpoints.up('sm')} {
        flex-direction: row;
        padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;
      }
    }

    &--tableHeader {
      margin-bottom: ${p => p.theme.spacing(1)}px;
      padding: 0 ${p => p.theme.spacing(2)}px;
      background-color: transparent;

      ${p => p.theme.breakpoints.up('sm')} {
        padding: 0 ${p => p.theme.spacing(4)}px;
      }

      .CustomerPortalClientsSelection__bookmarkStar {
        &:before {
          content: none;
        }
      }

      .CustomerPortalClientsSelection__client {
        .CustomerPortalClientsSelection__clientName {
          color: #858f95;
          font-size: 1.2rem;
          letter-spacing: 0.5px;
          line-height: 16px;
        }
      }

      .CustomerPortalClientsSelection__clientDetails {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;

        p {
          display: none;
          width: 50%;
          color: #858f95;
          font-size: 1.2rem;
          text-align: left;
          letter-spacing: 0.5px;
          line-height: 16px;
        }

        .CustomerPortalSupport--premiumStatus {
          text-align: center;
        }

        ${p => p.theme.breakpoints.up('sm')} {
          margin-left: ${p => p.theme.spacing(5)}px;
          width: 40%;

          p {
            display: block;
          }
        }
      }

      .CustomerPortalSupport--premiumMetaData {
        ${p => p.theme.breakpoints.up('sm')} {
          margin-left: ${p => p.theme.spacing(2)}px;
          width: 70%;
        }
      }
    }
  }

  .CustomerPortalClientsSelection__bookmarkStar {
    position: absolute;
    top: 50%;
    left: ${p => p.theme.spacing(3)}px;

    transform: translateY(-50%);

    width: ${p => p.theme.spacing(2)}px;
    height: ${p => p.theme.spacing(2)}px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      background: url(${favouriteIcon}) no-repeat;

      width: ${p => p.theme.spacing(2)}px;
      height: ${p => p.theme.spacing(2)}px;
    }

    &--bookmarked {
      &:before {
        background: url(${favouriteIconFill}) no-repeat;
      }
    }

    &:hover {
      &:before {
        background: url(${favouriteIconFill}) no-repeat;
      }
    }
  }

  .CustomerPortalClientsSelection__client {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-left: ${p => p.theme.spacing(9)}px;
    width: 100%;

    &--noBookmarkStar {
      margin-left: 0;
    }

    ${p => p.theme.breakpoints.up('sm')} {
      margin-left: ${p => p.theme.spacing(5)}px;
      width: 40%;

      &--noBookmarkStar {
        margin-left: 0;
      }
    }

    .CustomerPortalClientsSelection__clientName {
      max-width: 80%;
      color: ${p => p.theme.palette.ink[650]};
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 24px;

      ${p => p.theme.breakpoints.up('sm')} {
        font-size: 1.6rem;
      }

      &.CustomerPortalSupport--premiumSubject {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;

        &::before {
          width: 16px;
          height: 16px;
          content: '';
          flex-shrink: 0;
          background: url(${Stars_24}) no-repeat;
          margin-right: ${p => p.theme.spacing(1)}px;
        }
      }
    }

    .CustomerPortalClientsSelection__clientId {
      padding-top: ${p => p.theme.spacing(1)};
      color: ${p => p.theme.palette.ink[450]};
      font-size: 1rem;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }

  .CustomerPortalClientsSelection__clientDetails {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    flex: 1 0 auto;
    width: 100%;
    margin-top: 40px;
    padding-top: 10px;
    border-top: 1px solid #eeeeee;

    ${p => p.theme.breakpoints.up('sm')} {
      margin-left: ${p => p.theme.spacing(5)}px;
      width: calc(40% - 50px);

      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }

    &.CustomerPortalSupport--premiumMetaData {
      ${p => p.theme.breakpoints.up('sm')} {
        margin-left: ${p => p.theme.spacing(2)}px;
        width: 70%;

        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
    }

    p {
      width: 33.3%;
      color: #353c4a;
      font-size: 1.2rem;
      letter-spacing: 0.5px;
      line-height: 1.6rem;
      word-break: break-word;

      &.CustomerPortalClientsSelection__clientCountry--openTicket {
        flex: 0 0 auto;
        width: 20%;
        color: #f5222d;
        background: #ffe6e7;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
      }

      &.CustomerPortalClientsSelection__clientCountry--details {
        flex: 0 0 auto;
        width: 20%;
        color: #fff;
        background: rgb(0, 103, 223);
        border-radius: 8px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
      }

      &.CustomerPortalClientsSelection__clientCountry--closedTicket {
        flex: 0 0 auto;
        width: 20%;
        color: #858f95;
        background: #ecedee;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;

        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
      }

      &.CustomerPortalSupport--premiumStatus {
        padding: 4px 8px;
      }
    }
  }

  .CustomerPortalClientsSelection__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    height: 100%;
  }
`;
