// Reducer for collab space
import COLLAB_CONSTANTS from '../constants/collab.constants';
// Interfaces
import type { ISearchState } from '../interfaces/collab.interface';

// Reducer data fetching in collab space
export const searchReducer = (
  state: ISearchState,
  action: { type: string; payload?: { [key: string]: any } }
): ISearchState => {
  if (!action) {
    throw new Error('Action must be defined');
  }

  const documentsCopy = [ ...state.documents ];
  switch (action.type) {
    case COLLAB_CONSTANTS.FETCH_INIT:
      return {
        ...state,
        isCompleteSearching: false,
      };
    case COLLAB_CONSTANTS.FETCH_SUCCESS:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        documents: action.payload.documents,
        totalDocs: action.payload.totalDocs,
        isCompleteSearching: true,
      };
    case COLLAB_CONSTANTS.SORT_UPDATED:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.direction,
        currentPage: 1,
        skipResult: 0,
        isCompleteSearching: true,
      };
    case COLLAB_CONSTANTS.FOLDER_CREATED:
      if (!action.payload) {
        return { ...state };
      }

      documentsCopy.unshift({
        _id: action.payload._id,
        name: action.payload.name,
        display_name: action.payload.display_name,
        slug: action.payload.slug,
        is_folder: action.payload.is_folder,
        created_on: action.payload.created_on,
        created_by_uipath: action.payload.created_by_uipath,
        created_by_name: action.payload.created_by_name,
        created_by: action.payload.created_by,
        created_by_user: action.payload.created_by_user,
      });
      return {
        ...state,
        documents: documentsCopy,
        totalDocs: state.totalDocs + 1,
        currentPage: 1,
        skipResult: 0,
      };
    case COLLAB_CONSTANTS.KEYWORD_UPDATED:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        keyword: action.payload.keyword,
        currentPage: 1,
        skipResult: 0,
        isCompleteSearching: false,
      };
    case COLLAB_CONSTANTS.PAGE_CLICKED:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        currentPage: action.payload.pageNumber,
        skipResult: action.payload.skipResult,
        isCompleteSearching: true,
      };
    case COLLAB_CONSTANTS.ASSET_UPLOADED: {
      if (!action.payload) {
        return { ...state };
      }
      // Check end of documentsCopy array
      let isFolderCheckComplete = false;

      for (
        let i = 0;
        i < documentsCopy.length && isFolderCheckComplete === false;
        i++
      ) {
        if (documentsCopy[i].is_folder) {
          continue;
        } else {
          // Add it in here
          documentsCopy.splice(i, 0, {
            _id: action.payload._id,
            name: action.payload.name,
            display_name: action.payload.display_name,
            mime_type: action.payload.mime_type,
            slug: action.payload.slug,
            is_folder: action.payload.is_folder,
            created_on: action.payload.created_on,
            created_by_uipath: action.payload.created_by_uipath,
            created_by_name: action.payload.created_by_name,
            created_by: action.payload.created_by,
            created_by_user: action.payload.created_by_user,
          });

          isFolderCheckComplete = true;
        }
      }
      // Extra flag
      if (!isFolderCheckComplete
          && documentsCopy.length < action.payload.docsPerPage) {
        documentsCopy.push({
          _id: action.payload._id,
          name: action.payload.name,
          display_name: action.payload.display_name,
          mime_type: action.payload.mime_type,
          slug: action.payload.slug,
          is_folder: action.payload.is_folder,
          created_on: action.payload.created_on,
          created_by_uipath: action.payload.created_by_uipath,
          created_by_name: action.payload.created_by_name,
          created_by: action.payload.created_by,
          created_by_user: action.payload.created_by_user,
        });
      }

      return {
        ...state,
        currentPage: 1,
        skipResult: 0,
        totalDocs: state.totalDocs + 1,
        documents: documentsCopy,
      };
    }
    case COLLAB_CONSTANTS.FOLDER_CLICKED:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        folderViewId: action.payload.folder_id,
        currentPage: 1,
        skipResult: 0,
        isCompleteSearching: true,
      };
    case COLLAB_CONSTANTS.FETCH_FOLDER_SUCCESS:
      if (!action.payload) {
        return { ...state };
      }
      return {
        ...state,
        folderViewName: action.payload.folderName,
      };
    case COLLAB_CONSTANTS.FILE_DELETED:
      if (!action.payload) {
        return { ...state };
      }
      for (let i = 0; i < documentsCopy.length; i++) {
        if (documentsCopy[i]._id === action.payload.fileId) {
          documentsCopy.splice(i, 1);
        }
      }
      return {
        ...state,
        documents: documentsCopy,
        totalDocs: state.totalDocs - 1,
      };
    default:
      return { ...state };
  }
};
