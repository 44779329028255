import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

import * as styles from '../assets/css/ConfirmationModal';
// Components
import Button from './Button/Button';
import CustomerPortalModal from './Modal';

/**
 * @message is the key of localized string
 */
type IConfirmationModalProps = {
  message: string;
  open: boolean;
  primaryButtonText: string;
  secondaryButtontext: string;
  modalHeading?: string;
  handleConfirm: Function;
  handleClose: Function;
};

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    message,
    open,
    modalHeading,
    handleClose,
    handleConfirm,
    primaryButtonText,
    secondaryButtontext,
  } = props;

  const children = (
    <styles.container className="Modal-Fields-Container">
      <styles.message dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
      <Button
        className="ConfirmationModal__ConfirmButton"
        text={primaryButtonText}
        onClick={() => {
          handleConfirm();
        }}
      />
      <Button
        text={secondaryButtontext}
        className="ConfirmationModal__SecondaryButton Secondary"
        onClick={() => {
          handleClose();
        }}
      />
    </styles.container>
  );

  return (
    <CustomerPortalModal
      testId="CustomerPortalModal"
      modalTitle="Confirmation Modal"
      modalDescription="Confirmation Modal"
      modalHeading={modalHeading}
      open={open}
      handleClose={() => {
        handleClose();
      }}
      children={children}
    />
  );
};

export default ConfirmationModal;
