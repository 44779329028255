import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  DateSectionContainer,
  DetailSectionContainer,
  HeroTitle,
  KnownIssueDetailsInfo,
  KnownIssueDetailsInfoDiv,
  KnownIssueDetailsInfoLabel,
  KnownIssuesContainer,
  ListTag,
  ListTagContainer,
  LoaderContainer,
  SectionContainer,
  SectionContent,
  SectionTitle,
} from '../../assets/css/KnownIssues/index';
import { axiosGet } from '../../client/axios';
import Loader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { CustomEventType } from '../../constants/caseAssist.constants';
import { KNOWN_ISSUE_DETAILS_URL } from '../../constants/network.constants';
import { KNOWN_ISSUE_DETAILS_PAGEVIEW } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../store';
import { logCustomEvent } from '../../utils/caseAssist';
import { featureFlag } from '../../utils/featureFlag';
import CustomerPortalPage404 from '../404';

const CustomerPortalKnownIssuesDetails = (props: any) => {
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();
  const { key } = useParams<any>();
  const history: any = useHistory();
  const [ knownIssueDetails, setKnownIssueDetails ] = useState<any>([]);
  const [ isKnownIssuesEnabled, setIsKnownIssuesEnabled ] = useState<any>(null);
  const [ showError, setShowError ] = useState(false);
  const [ back, setBack ] = useState(false);
  const { state } = useContext(StoreContext);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const getKnownIssuesData = async () => {
    try {
      const knownIssueData = await axiosGet(
        KNOWN_ISSUE_DETAILS_URL,
        state.companyId,
        await getAccessToken(),
        { params: { key } }
      );
      if (knownIssueData.status === 200 && knownIssueData.data) {
        setKnownIssueDetails(knownIssueData.data);
        const knownIssueDetailsData = {
          'issue_identifier': knownIssueData.data.key,
          'issue_summary': knownIssueData.data.summary,
          'issue_status': knownIssueData.data.status,
          'issue_affected_product': knownIssueData.data.components,
          'issue_affected_versions': knownIssueData.data.affectsVersions,
          'issue_fix_versions': knownIssueData.data.fixVersions,
          'issue_url': window.location.href.toString(),
        };
        logCustomEvent(
          CustomEventType.KNOWN_ISSUE_DETAILS_VIEW,
          knownIssueDetailsData,
          state.companyId,
          await getAccessToken(),
        );
        triggerTrackEventWithStateData(KNOWN_ISSUE_DETAILS_PAGEVIEW, {
          IssueIdentifier: knownIssueData.data.key,
          IssueSummary: knownIssueData.data.summary,
          IssueStatus: knownIssueData.data.status,
          IssueAffectedProduct: knownIssueData.data.components,
          IssueAffectedVersions: knownIssueData.data.affectsVersions,
          IssueFixVersions: knownIssueData.data.fixVersions,
        });
      }
    } catch (e) {
      setShowError(true);
      console.log(e);
    }
  };

  const updateKnownIssuesEnabled = async () => {
    try {
      const { companyId } = state;

      const isKnownIssuesFeatureEnabled = await featureFlag.isKnownIssuesEnabled(
        companyId,
        await getAccessToken()
      );

      setIsKnownIssuesEnabled(isKnownIssuesFeatureEnabled);
    } catch (e) {
      setIsKnownIssuesEnabled(false);
    }
  };

  useEffect(() => {
    updateKnownIssuesEnabled();
    getKnownIssuesData();
  }, []);

  useEffect(() => {
    if (back) {
      history.goBack();
    }
  }, [ back, history ]);

  const displayContent = (section: any) => {
    if (!section || section.length === 0) {
      return <div>N/A</div>;
    }

    return section?.map((item: any, index: number) => {
      switch (item.type) {
        case 'heading': {
          return <div className='heading'>{displayParagraphContent(item, index)}</div>;
        }
        case 'paragraph': {
          return displayParagraphContent(item, index);
        } case 'codeBlock': {
          return (
            <div
              className='codeBlock'
              key={index}>
              {item.content.map((content: any, contentIndex: number) => {
                if (content.type === 'text') {
                  return (
                    <div key={contentIndex}>
                      {content.text.split('\n').map((line: any, lineIndex: number) => (
                        <div key={lineIndex}>
                          {line}
                          <br />
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        } case 'orderedList': {
          return (
            <ol key={index}>
              {item.content.map((listItem: any, listItemIndex: number) => (
                <li key={listItemIndex}>
                  {listItem.content.map((content: any, contentIndex: number) => {
                    if (content.type === 'paragraph') {
                      return displayParagraphContent(content, contentIndex);
                    }
                    return null;
                  })}
                </li>
              ))}
            </ol>
          );
        } case 'bulletList': {
          return (
            <ul key={index}>
              {item.content.map((listItem: any, listItemIndex: number) => (
                <li key={listItemIndex}>
                  {listItem.content.map((content: any, contentIndex: number) => {
                    if (content.type === 'paragraph') {
                      return displayParagraphContent(content, contentIndex);
                    }
                    return null;
                  })}
                </li>
              ))}
            </ul>
          );
        }
        default: {
          return null;
        }
      }
    });
  };

  const displayParagraphContent = (content: any, contentIndex: any) => (
    <div
      key={contentIndex}
      className='paragraphBlock'>
      {content.content.map(
        (paragraphContent: any, paragraphContentIndex: number) => {
          switch (paragraphContent.type) {
            case 'text': {
              if (paragraphContent.marks?.length > 0) {
                const marks = paragraphContent.marks.map((mark: any) => {
                  if (mark.type === 'subsup' && mark.attrs?.type) {
                    return mark.attrs.type;
                  }
                  return mark.type;
                }).join(' ');

                if (marks.includes('link')) {
                  return <a
                    href={paragraphContent.marks[0].attrs.href}
                    target='_blank'
                    className={marks}
                    rel="noreferrer">
                    {paragraphContent.text}
                  </a>;
                }
                return <div className={marks}>{paragraphContent.text}</div>;

              }
              return (
                <div key={paragraphContentIndex}>
                  {paragraphContent.text.split('\n').map((line: any, lineIndex: number) => (
                    <div key={lineIndex}>
                      {line}
                      {lineIndex < paragraphContent.text.split('\n').length - 1 && <br />}
                    </div>
                  ))}
                </div>
              );

            } case 'hardBreak': {
              return <div
                className='hardBreak'
                key={contentIndex} />;
            } case 'inlineCard': {
              return <a
                href={paragraphContent.attrs.url}
                target='_blank'
                rel="noreferrer">
                {paragraphContent.attrs.url}
              </a>;
            } default: {
              return null;
            }
          }
        }
      )}
    </div>
  );

  if (isKnownIssuesEnabled === null) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  } else if (isKnownIssuesEnabled === false) {
    return (<CustomerPortalPage404 />);
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'known_issues_details_page_title',
            'Known Issue Details | Customer Portal'
          )}
        </title>
      </Helmet>
      {
        knownIssueDetails.length !== 0 ? (
          <KnownIssuesContainer className='detailsPage'>
            <Container>
              <div
                className="heroBackButton"
                onClick={() => {
                  setBack(true);
                }}
              >
                {t('support_case_details_go_back_btn', 'Go Back')}
              </div>
              <HeroTitle data-testid="CaseTitle">
                {key}
                {' '}
                -
                {' '}
                {t('known_issues_details_heading', 'Known Issue')}
              </HeroTitle>

              <div>
                <DateSectionContainer>
                  <KnownIssueDetailsInfoDiv>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_date_created', 'Date Created:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      {knownIssueDetails?.dateCreated}
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>
                  <KnownIssueDetailsInfoDiv>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_date_updated', 'Date Updated:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      {knownIssueDetails?.dateUpdated}
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>
                </DateSectionContainer>

                <DetailSectionContainer>
                  <KnownIssueDetailsInfoDiv className='affected-product'>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_affected_product', 'Affected Product:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      {knownIssueDetails?.components}
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>

                  <KnownIssueDetailsInfoDiv>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_status', 'Status:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      <ListTag
                        className={knownIssueDetails.status === 'In Progress' ? 'yellow' :
                          knownIssueDetails.status === 'Fixed' ? 'green' : 'red'}>
                        {knownIssueDetails.status}
                      </ListTag>
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>

                  <KnownIssueDetailsInfoDiv>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_affected_versions', 'Affected Versions:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      <ListTagContainer>
                        {knownIssueDetails?.affectsVersions?.map((version: any, versionIndex: number) => (
                          <ListTag key={versionIndex}>{version}</ListTag>
                        ))}
                      </ListTagContainer>
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>

                  <KnownIssueDetailsInfoDiv>
                    <KnownIssueDetailsInfoLabel>
                      <div>{t('known_issues_details_fix_versions', 'Fix Versions:')}</div>
                    </KnownIssueDetailsInfoLabel>
                    <KnownIssueDetailsInfo>
                      <ListTagContainer>
                        {knownIssueDetails?.fixVersions?.map((version: any, versionIndex: number) => (
                          <ListTag key={versionIndex}>{version}</ListTag>
                        ))}
                      </ListTagContainer>
                    </KnownIssueDetailsInfo>
                  </KnownIssueDetailsInfoDiv>
                </DetailSectionContainer>

                <SectionContainer>
                  <SectionTitle>
                    <div>{t('known_issues_details_issue_summary', 'Issue Summary')}</div>
                  </SectionTitle>
                  <SectionContent>
                    {knownIssueDetails?.summary}
                  </SectionContent>
                </SectionContainer>

                <SectionContainer>
                  <SectionTitle>
                    <div>{t('known_issues_details_overview', 'Overview')}</div>
                  </SectionTitle>
                  <SectionContent>
                    {displayContent(knownIssueDetails?.overview)}
                  </SectionContent>
                </SectionContainer>

                <SectionContainer>
                  <SectionTitle>
                    <div>{t('known_issues_details_steps_to_reproduce', 'Steps to Reproduce')}</div>
                  </SectionTitle>
                  <SectionContent>
                    {displayContent(knownIssueDetails?.stepsToReproduce)}
                  </SectionContent>
                </SectionContainer>

                <SectionContainer>
                  <SectionTitle>
                    <div>{t('known_issues_details_workaround', 'Workaround')}</div>
                  </SectionTitle>
                  <SectionContent>
                    {displayContent(knownIssueDetails?.howToAvoidInFuture)}
                  </SectionContent>
                </SectionContainer>
              </div>

            </Container>
          </KnownIssuesContainer>
        ) : showError ? (
          <ErrorPage
            errorType='404'
            header={t('known_issues_details_error_message', 'The issue does not exist.')}
            description={t(
              'page_404_description',
              'This page was not found. You may have mistyped the address or the page may have moved.'
            )}>
            <Link
              data-testid='ErrorPage-404__CTA'
              className='link'
              to='/known-issues'>
              {t('known_issues_details_error_redirect_message', 'Go to Known Issues page')}
            </Link>
          </ErrorPage>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>

        )
      }
    </>
  );
};

export default CustomerPortalKnownIssuesDetails;
