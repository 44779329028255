/**
 * This is a shared Cart Panel with RFQ and HAPO
 */
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import RFQ, {
  AUTOMATION_SUITE,
  CLOUD,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
  SERVICE,
  STANDALONE,
} from '../../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../../interfaces/contextStore';
import { getLicensesGroupedByType } from '../../lib/requestForQuote.utils';
import { StoreContext } from '../../store';
import type { HapoSkuType } from '../../types/hapo.types';
import type {
  ApiSkuType,
  SkuType,
} from '../../types/requestForQuote.types';
import Button from '../Button/Button';
import * as styles from './../../assets/css/RequestForQuote/RequestForQuote';

const CartPanel = ({
  licenses = [],
  isCartEmpty = true,
  cartEmptyText,
  cartProducts = [],
  continueButtonDisabled = true,
  buttonLoading = false,
  availableActiveOrchestratorsId = [],
  isInvalidHapoConfig = false,
  invalidProducts = [],
  handleCreateCartClick,
  handlePanelLicenseClick,
  handleRemoveTopLevel,
  handleRemoveChildItem,
  handleQuantityChange,
}: {
  licenses: Array<SkuType | HapoSkuType>;
  isCartEmpty: boolean;
  cartEmptyText: string;
  cartProducts: GlobalStoreState['rfqCart']['products'];
  continueButtonDisabled: boolean;
  buttonLoading: boolean;
  availableActiveOrchestratorsId: string[];
  isInvalidHapoConfig?: boolean;
  invalidProducts: any[];
  handleCreateCartClick: (e?: any) => void;
  handlePanelLicenseClick: (
    cartProductId?: string
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
  handleRemoveTopLevel: (
    cartProductId?: string | undefined,
    productId?: string | undefined
  ) => (event: React.MouseEvent<HTMLParagraphElement>) => void;
  handleRemoveChildItem: (
    parentOrchId: string,
    productId: string
  ) => (event: React.MouseEvent<HTMLParagraphElement>) => void;
  handleQuantityChange: (
    productId: string,
    parentOrchId?: string | undefined
  ) => (event: any) => void;
}) => {
  const baseClass = 'RequestForQuote';
  const { state } = useContext(StoreContext);
  const { t } = useTranslation('common');
  const generateCartPanelSubtitle = (
    baseClass: string,
    subtitleText: string,
    children: JSX.Element[],
    displayDivider: boolean = false
  ) => (
    <>
      {displayDivider && (
        <div className={`${baseClass}__Cart-Products-Divider`} />
      )}
      <div className={`${baseClass}__Cart-Standalone-Products-Container`}>
        <p
          className={`${baseClass}__Cart-Standalone-Container-Title`}
          data-testid="TopLevelProductTitle">
          {subtitleText}
        </p>
        {children}
      </div>
    </>
  );

  const shouldDisplayDivider = (
    type: ApiSkuType,
    groupedLicenses: any
  ): boolean => {
    const hasOnPremOrchLicenses =
      groupedLicenses[NEW_ORCHESTRATOR]?.length > 0 ||
      groupedLicenses[EXISTING_ORCHESTRATOR]?.length > 0;
    const hasStandaloneLicenses = groupedLicenses.Standalone?.length > 0;
    const hasServiceLicenses = groupedLicenses.Service?.length > 0;
    const hasProcessMiningLicenses =
      groupedLicenses[NEW_PROCESS_MINING]?.length > 0 ||
      groupedLicenses[EXISTING_PROCESS_MINING]?.length > 0;
    const hasAutomationSuiteBundleLicenses =
      groupedLicenses[EXISTING_AUTOMATION_SUITE]?.length > 0 ||
      groupedLicenses[AUTOMATION_SUITE]?.length > 0;

    switch (type) {
      case CLOUD:
        return hasOnPremOrchLicenses;
      case EXISTING_CLOUD:
        return (
          hasOnPremOrchLicenses ||
          hasStandaloneLicenses ||
          hasServiceLicenses ||
          hasProcessMiningLicenses ||
          hasAutomationSuiteBundleLicenses
        );
      case NEW_ORCHESTRATOR:
      case EXISTING_ORCHESTRATOR:
        break;
      case NEW_PROCESS_MINING:
      case EXISTING_PROCESS_MINING:
        return (
          hasOnPremOrchLicenses || hasStandaloneLicenses || hasServiceLicenses
        );
      case AUTOMATION_SUITE:
        return hasOnPremOrchLicenses;
      case EXISTING_AUTOMATION_SUITE:
        return (
          hasOnPremOrchLicenses ||
          hasStandaloneLicenses ||
          hasServiceLicenses ||
          hasProcessMiningLicenses
        );
      case STANDALONE:
        return hasOnPremOrchLicenses;
      case SERVICE:
        return hasOnPremOrchLicenses || hasStandaloneLicenses;
      default:
        return false;
    }
    return false;
  };

  const generateLicenseForProductType = (
    type: ApiSkuType,
    groupedLicenses: any,
    parentOrchId: string | undefined = undefined,
    parentLicenseCode: string | undefined = undefined,
    parentOrchIndex: number | undefined = undefined
  ) => {
    let products: any;

    switch (type) {
      case CLOUD:
        products = groupedLicenses.Cloud;
        break;
      case AUTOMATION_SUITE:
        products = groupedLicenses['Automation Suite'];
        break;
      case STANDALONE:
        products = groupedLicenses.Standalone;
        break;
      case SERVICE:
        products = groupedLicenses.Service;
        break;
    }

    return products?.map(
      (skuProduct: any, i: number) =>
        generateLicense(
          skuProduct.type,
          skuProduct.sku_name!,
          skuProduct._id,
          skuProduct.quantity!,
          i,
          skuProduct.sku_id!,
          parentOrchId,
          parentLicenseCode,
          parentOrchIndex
        ) ?? []
    );
  };

  const generateLicense = (
    productType: string,
    productSkuName: string,
    cartProductId: string | undefined,
    productQuantity: number,
    key: number,
    productId: string,
    parentOrchId: string | undefined,
    existingLicenseCode: string | undefined,
    parentOrchIndex: number | undefined
  ) => {
    const baseClass = 'Checkout';
    let classes = `${baseClass}__Cart-Product`;
    let quantityHelperText = undefined;
    const isStandalone = productType === STANDALONE;
    const isService = productType === SERVICE;
    // In RFQ, Cloud is treated as standalone
    const isCloud = productType === CLOUD;
    const isNewOrch = productType === NEW_ORCHESTRATOR;
    const isExistingOrch = productType === EXISTING_ORCHESTRATOR;
    const isNewProcessMining = productType === NEW_PROCESS_MINING;
    const isExistingProcessMining = productType === EXISTING_PROCESS_MINING;
    const isAutomationSuite = productType === AUTOMATION_SUITE;
    // In HAPO, cloud and Automation Suite are a bundle
    const isExistingCloud = productType === EXISTING_CLOUD;
    const isExistingAutomationSuite = productType === EXISTING_AUTOMATION_SUITE;

    if (isStandalone) {
      classes += ` ${baseClass}__Cart-Product-Standalone`;
    } else if (isCloud || isExistingCloud) {
      classes += ` ${baseClass}__Cart-Product-Cloud`;
    } else if (isNewOrch) {
      classes += ` ${baseClass}__Cart-Product-New-Orch`;
    } else if (isExistingOrch) {
      classes += ` ${baseClass}__Cart-Product-Existing-Orch`;
    } else if (isService) {
      classes += ` ${baseClass}__Cart-Product-Service`;
    } else if (isAutomationSuite || isExistingAutomationSuite) {
      classes += ` ${baseClass}__Cart-Product-Cloud`;
    }

    const productName = (
      <h4
        className={`${baseClass}__Cart-Product-Title`}
        data-testid="Cart__productName"
      >
        {productSkuName}
      </h4>
    );

    let removeItem;
    if (
      isExistingOrch ||
      isNewOrch ||
      isNewProcessMining ||
      isExistingProcessMining ||
      isExistingCloud ||
      isExistingAutomationSuite ||
      isAutomationSuite ||
      isCloud
    ) {
      removeItem = (
        <p
          onClick={handleRemoveChildItem(parentOrchId || '', productId || '')}
          data-child-id={cartProductId}
          data-parent-id={parentOrchId}
          className={`${baseClass}__Cart-Product-Remove`}
          data-testid="Cart__removeChildItem"
        >
          {t('hapo_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    } else if (isStandalone || isService) {
      removeItem = (
        <p
          onClick={handleRemoveTopLevel(cartProductId, undefined)}
          className={`${baseClass}__Cart-Product-Remove`}
          id={cartProductId}
        >
          {t('hapo_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    }

    const rawLicense = licenses.find(sku => sku._id === productId);
    const invalidProduct = invalidProducts.find(
      (product: any) =>
        product.sku_id === productId && product.licenseCode === existingLicenseCode
    );
    if (rawLicense) {
      if (
        rawLicense.min_quantity &&
        productQuantity < rawLicense.min_quantity
      ) {
        quantityHelperText = t(
          'rfq_checkout_cart_required_min_quantity_helper',
          'A minimum of {{minQuantity}} is required',
          { minQuantity: rawLicense.min_quantity }
        );
      } else if (
        productQuantity >
        (rawLicense.max_quantity
          ? rawLicense.max_quantity
          : Number.MAX_SAFE_INTEGER)
      ) {
        quantityHelperText = t(
          'rfq_checkout_cart_max_quantity_helper',
          'The maximum limit of {{maxQuantity}} has been reached',
          { maxQuantity: rawLicense.max_quantity }
        );
      } else if (productQuantity < 1) {
        quantityHelperText = t(
          'rfq_checkout_cart_enter_min_one_item_helper',
          'Please enter quantity of 1 or more'
        );
      } else if (invalidProduct) {
        quantityHelperText = productQuantity - invalidProduct.excessQuantity > 0 ?
          t(
            'hapo_checkout_cart_invalid_sku_info_text',
            `Please reduce SKU quantity to ${productQuantity - invalidProduct.excessQuantity} to continue.`,
            { maxQuantity: productQuantity - invalidProduct.excessQuantity }
          )
          : t(
            'hapo_checkout_cart_remove_sku_info_text',
            'Remove this SKU to continue, Key already entitled.'
          );

      }
    } else {
      quantityHelperText = t(
        'hapo_checkout_cart_product_not_in_catalog_helper',
        'This product no longer exists in the catalog. Please remove it from the cart to continue.'
      );
    }

    return (
      <div
        className={classes}
        key={cartProductId || key}
        onClick={
          isStandalone || isService
            ? handlePanelLicenseClick(cartProductId)
            : undefined
        }
        style={{ cursor: 'pointer' }}
      >
        {(isStandalone || isCloud || isService || isAutomationSuite) && (
          <div className={`${baseClass}__Cart-Product-Standalone-Info`}>
            {productName}
          </div>
        )}
        {(isNewOrch ||
          isExistingOrch ||
          isNewProcessMining ||
          isExistingProcessMining ||
          isExistingCloud ||
          isExistingAutomationSuite) &&
          productName}
        <div className={`${baseClass}__Cart-Quantity-Container`}>
          <>
            <TextField
              className={`${baseClass}__Cart-Product-Quantity`}
              variant={'outlined' as any}
              value={productQuantity}
              onChange={handleQuantityChange(productId!, parentOrchId)}
              onClick={e => e.stopPropagation()}
              onFocus={e => e.target.select()}
              error={false}
              required={false}
              InputLabelProps={{ shrink: true }}
              type="number"
              InputProps={{ className: 'Tall' }}
              inputProps={{
                'data-child-id': cartProductId,
                'data-parent-id': parentOrchId,
                'data-is-standalone':
                  isStandalone || isCloud || isService || isAutomationSuite,
                'data-parent-index': parentOrchIndex,
                'data-child-index': key,
              }}
              id={cartProductId}
              data-testid="Cart__quantityInput"
            />
            {removeItem}
          </>
          {quantityHelperText && (
            <p className={`${baseClass}__Cart-Product-HelperText`}>
              {quantityHelperText}
            </p>
          )}
        </div>
      </div>
    );
  };

  const generateProducts = () => {
    const baseClass = 'Checkout';

    if (isCartEmpty) {
      return <p>{cartEmptyText}</p>;
    }
    // Group Licenses
    const groupedRes = getLicensesGroupedByType(cartProducts);
    const hasOnPremOrchLicenses =
      groupedRes[NEW_ORCHESTRATOR]?.length > 0 ||
      groupedRes[EXISTING_ORCHESTRATOR]?.length > 0;
    const hasStandaloneLicenses = groupedRes.Standalone?.length > 0;
    const hasServiceLicenses = groupedRes.Service?.length > 0;
    const hasProcessMiningLicenses =
      groupedRes[NEW_PROCESS_MINING]?.length > 0 ||
      groupedRes[EXISTING_PROCESS_MINING]?.length > 0;
    const hasCloudBundleLicenses =
      groupedRes[EXISTING_CLOUD]?.length > 0 ||
      groupedRes[CLOUD]?.length > 0;
    const hasAutomationSuiteBundleLicenses =
      groupedRes[AUTOMATION_SUITE]?.length > 0 ||
      groupedRes[EXISTING_AUTOMATION_SUITE]?.length > 0;
    return (
      <div
        className={`${baseClass}__Cart-ContentWrapper${
          isCartEmpty ? '' : '--nonEmpty'
        }`}
      >
        {hasOnPremOrchLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.onPrem,
            (
              groupedRes[NEW_ORCHESTRATOR]?.map(newOrch => (
                <styles.CartPanelOrchWrapper
                  key={newOrch._id}
                  onClick={handlePanelLicenseClick(newOrch._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newOrch.sku_name}
                    </h4>
                    <Tooltip
                      title={
                        t(
                          'rfq_checkout_cart_remove_item_tooltip_1',
                          'Removing Orchestrator will also remove the products for it.'
                        ) as string
                      }
                      placement="bottom"
                    >
                      <p
                        onClick={handleRemoveTopLevel(newOrch._id, undefined)}
                        id={newOrch._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                        data-testid="Cart__removeTopLevelItem"
                      >
                        {t('hapo_checkout_cart_remove_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newOrch.products && newOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newOrch.products.map((orchProduct, i) => generateLicense(
                        NEW_ORCHESTRATOR,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newOrch._id,
                        undefined,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              )) || []
            ).concat(
              groupedRes[EXISTING_ORCHESTRATOR]?.map(existingOrch => (
                <styles.CartPanelOrchWrapper
                  key={existingOrch._id}
                  onClick={handlePanelLicenseClick(existingOrch._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator`}>
                    <p className={`${baseClass}__Cart-Orchestrator-Title`}>
                      {existingOrch.display_name ||
                        existingOrch.existing_license_name}
                    </p>
                    <p className={`${baseClass}__Cart-Orchestrator-Code`}>
                      {existingOrch.existing_license_code}
                    </p>
                    {!availableActiveOrchestratorsId.includes(
                      existingOrch.existing_license_code || ''
                    ) && (
                      <p
                        className={`${baseClass}__Cart-Orchestrator-HelperText`}
                      >
                        {t(
                          'hapo_checkout_cart_invalid_license_info_text',
                          'This license is no longer active. Please remove products to continue.'
                        )}
                      </p>
                    )}
                  </div>
                  {existingOrch.products && existingOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {existingOrch.products.map((orchProduct, i) => generateLicense(
                        EXISTING_ORCHESTRATOR,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        existingOrch._id,
                        existingOrch.existing_license_code,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              ))
            ),
            false
          )}
        {hasStandaloneLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.standalone,
            generateLicenseForProductType(STANDALONE, groupedRes),
            shouldDisplayDivider(STANDALONE, groupedRes)
          )}
        {hasServiceLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.services,
            generateLicenseForProductType(SERVICE, groupedRes),
            shouldDisplayDivider(SERVICE, groupedRes)
          )}
        {hasProcessMiningLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.processMining,
            (
              groupedRes[NEW_PROCESS_MINING]?.map(newBundle => (
                <styles.CartPanelOrchWrapper
                  key={newBundle._id}
                  onClick={handlePanelLicenseClick(newBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newBundle.sku_name}
                    </h4>
                    <Tooltip
                      title={
                        t(
                          'hapo_checkout_cart_remove_item_tooltip_1',
                          'Removing server will also remove the products for it.'
                        ) as string
                      }
                      placement="bottom"
                    >
                      <p
                        onClick={handleRemoveTopLevel(newBundle._id, undefined)}
                        id={newBundle._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('hapo_checkout_cart_remove_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newBundle.products && newBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newBundle.products.map((orchProduct, i) => generateLicense(
                        NEW_PROCESS_MINING,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newBundle._id,
                        undefined,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              )) || []
            ).concat(
              groupedRes[EXISTING_PROCESS_MINING]?.map(existingBundle => (
                <styles.CartPanelOrchWrapper
                  key={existingBundle._id}
                  onClick={handlePanelLicenseClick(existingBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator`}>
                    <p className={`${baseClass}__Cart-Orchestrator-Title`}>
                      {existingBundle.display_name ||
                        existingBundle.existing_license_name}
                    </p>
                    <p className={`${baseClass}__Cart-Orchestrator-Code`}>
                      {existingBundle.existing_license_code}
                    </p>
                    {!availableActiveOrchestratorsId.includes(
                      existingBundle.existing_license_code || ''
                    ) && (
                      <p
                        className={`${baseClass}__Cart-Orchestrator-HelperText`}
                      >
                        This license is no longer active. Please remove products
                        to continue.
                      </p>
                    )}
                  </div>
                  {existingBundle.products &&
                    existingBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {existingBundle.products.map((orchProduct, i) => generateLicense(
                        EXISTING_PROCESS_MINING,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        existingBundle._id,
                        existingBundle.existing_license_code,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              ))
            ),
            shouldDisplayDivider(EXISTING_PROCESS_MINING, groupedRes)
          )}
        {hasAutomationSuiteBundleLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.automationSuite,
            (
              groupedRes[AUTOMATION_SUITE]?.map(newBundle => (
                <styles.CartPanelOrchWrapper
                  key={newBundle._id}
                  onClick={handlePanelLicenseClick(newBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newBundle.sku_name}
                    </h4>
                    <Tooltip
                      title={
                        t(
                          'hapo_checkout_cart_remove_item_tooltip_1',
                          'Removing server will also remove the products for it.'
                        ) as string
                      }
                      placement="bottom"
                    >
                      <p
                        onClick={handleRemoveTopLevel(newBundle._id, undefined)}
                        id={newBundle._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('hapo_checkout_cart_re move_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newBundle.products && newBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newBundle.products.map((orchProduct, i) => generateLicense(
                        AUTOMATION_SUITE,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newBundle._id,
                        undefined,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              )) || []
            ).concat(
              groupedRes[EXISTING_AUTOMATION_SUITE]?.map(existingBundle => (
                <styles.CartPanelOrchWrapper
                  key={existingBundle._id}
                  onClick={handlePanelLicenseClick(existingBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator`}>
                    <p className={`${baseClass}__Cart-Orchestrator-Title`}>
                      {existingBundle.display_name ||
                        existingBundle.existing_license_name}
                    </p>
                    <p className={`${baseClass}__Cart-Orchestrator-Code`}>
                      {existingBundle.existing_license_code}
                    </p>
                    {!availableActiveOrchestratorsId.includes(
                      existingBundle.existing_license_code || ''
                    ) && (
                      <p
                        className={`${baseClass}__Cart-Orchestrator-HelperText`}
                      >
                        This license is no longer active. Please remove products
                        to continue.
                      </p>
                    )}
                  </div>
                  {existingBundle.products &&
                    existingBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {existingBundle.products.map((orchProduct, i) => generateLicense(
                        EXISTING_AUTOMATION_SUITE,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        existingBundle._id,
                        existingBundle.existing_license_code,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              ))
            ),
            hasOnPremOrchLicenses ||
              hasStandaloneLicenses ||
              hasServiceLicenses ||
              hasProcessMiningLicenses
          )}
        {hasCloudBundleLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.cloud,
            (
              groupedRes[CLOUD]?.map(newBundle => (
                <styles.CartPanelOrchWrapper
                  key={newBundle._id}
                  onClick={handlePanelLicenseClick(newBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newBundle.sku_name}
                    </h4>
                    <Tooltip
                      title={
                        t(
                          'hapo_checkout_cart_remove_item_tooltip_1',
                          'Removing server will also remove the products for it.'
                        ) as string
                      }
                      placement="bottom"
                    >
                      <p
                        onClick={handleRemoveTopLevel(newBundle._id, undefined)}
                        id={newBundle._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('hapo_checkout_cart_re move_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newBundle.products && newBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newBundle.products.map((orchProduct, i) => generateLicense(
                        CLOUD,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newBundle._id,
                        undefined,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              )) || []
            ).concat(
              groupedRes[EXISTING_CLOUD]?.map(existingBundle => (
                <styles.CartPanelOrchWrapper
                  key={existingBundle._id}
                  onClick={handlePanelLicenseClick(existingBundle._id)}
                >
                  <div className={`${baseClass}__Cart-Orchestrator`}>
                    <p className={`${baseClass}__Cart-Orchestrator-Title`}>
                      {existingBundle.display_name ||
                        existingBundle.existing_license_name}
                    </p>
                    <p className={`${baseClass}__Cart-Orchestrator-Code`}>
                      {existingBundle.existing_license_code}
                    </p>
                    {!availableActiveOrchestratorsId.includes(
                      existingBundle.existing_license_code || ''
                    ) && (
                      <p
                        className={`${baseClass}__Cart-Orchestrator-HelperText`}
                      >
                        This license is no longer active. Please remove products
                        to continue.
                      </p>
                    )}
                  </div>
                  {existingBundle.products &&
                    existingBundle.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {existingBundle.products.map((orchProduct, i) => generateLicense(
                        EXISTING_CLOUD,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        existingBundle._id,
                        existingBundle.existing_license_code,
                        undefined
                      ))}
                    </div>
                  )}
                </styles.CartPanelOrchWrapper>
              ))
            ),
            shouldDisplayDivider(EXISTING_CLOUD, groupedRes)
          )}
      </div>
    );
  };

  const generateWarningMessage = () => {
    const showWarningMessage = isInvalidHapoConfig;
    const disclaimerText = state.isUtoEnabled
      ? t(
        'uto_cart_disclaimer',
        'Please verify that there are sufficient licenses available in the UiPath Tailored Offering. Click here to navigate to License Management'
      )
      : t(
        'hapo_cart_disclaimer',
        'Please verify that there are sufficient licenses available in the Enterprise Agreement. Click here to navigate to License Management'
      );

    if (showWarningMessage) {
      return (
        <p className="Checkout__Cart-Hapo-HelperTextContainer">
          <Link
            to="/company?section=hapo"
            className="Checkout__Cart-Hapo-HelperText"
          >
            {disclaimerText}
          </Link>
        </p>
      );
    }
  };

  return (
    <styles.CartPanel>
      {licenses.length > 0 && (
        <>
          <div
            className={`Checkout__Cart-Inner ${
              isCartEmpty ? '' : 'Checkout__Cart-Inner--nonEmpty'
            }`}
          >
            <h3 className={`${baseClass}__Cart-Title`}>
              {t('hapo_checkout_cart_title', 'Cart')}
            </h3>
            {generateWarningMessage()}
            {generateProducts()}
          </div>
          <styles.CartPanelContinueButtonWrapper>
            <Button
              className={`${baseClass}__continueToCheckout__CTA`}
              onClick={handleCreateCartClick}
              text={t('hapo_continue_to_checkout_cta', 'Continue')}
              disabled={continueButtonDisabled}
              isValid={!continueButtonDisabled}
              isLoading={buttonLoading}
            />
          </styles.CartPanelContinueButtonWrapper>
        </>
      )}
    </styles.CartPanel>
  );
};

export default CartPanel;
