import {
  FormControl,
  FormHelperText,
  InputLabel,
  Switch,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { axiosPatch } from '../../client/axios';
import { UPDATE_COMPANY_SETTINGS_URL } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';

const Container = styled.div`
  background-color: ${p => p.theme.palette.common.white};
  color: ${p => p.theme.palette.common.black};
  border-radius: ${p => p.theme.spacing(1)}px;
  padding: ${p => p.theme.spacing(2)}px;
  margin: ${p => p.theme.spacing(2)}px 0px;
  display: flex;
  flex-direction: column;


  .MuiInputLabel-root {
    color: ${p => p.theme.palette.common.black};
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2rem;
    position: unset;
    transform: none;
  }

  .MuiFormHelperText-root {
    color: ${p => p.theme.palette.grey[600]};
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: ${p => p.theme.spacing(2)}px;
`;

const CompanyAccessSettings = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();

  const isEditCompanySettingsAllowed = UserPermissionsHelper.isEditCompanySettingsAllowed();

  const updateCompanySettings = async (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const isSelfRegistrationEnabled = !checked;

    try {
      const res = await axiosPatch(
        UPDATE_COMPANY_SETTINGS_URL,
        state.companyId,
        await getAccessToken(),
        { updateObject: { isSelfRegistrationEnabled } }
      );

      if (res.status === 201) {
        dispatch({
          type: 'setIsSelfRegistrationEnabled',
          payload: isSelfRegistrationEnabled,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <Title>
        {t('company_access_settings_title', 'Account Access Settings')}
      </Title>
      <FormControl>
        <InputLabel htmlFor="">
          {t('company_access_settings_toggle_label', 'Restrict access to invite only')}
        </InputLabel>
        <Switch
          checked={!state.isSelfRegistrationEnabled}
          onChange={updateCompanySettings}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          disabled={!isEditCompanySettingsAllowed}
          data-testid="company-access-settings-switch"
        />
        <FormHelperText>
          {t(
            'company_access_settings_toggle_helper_text',
            'When the switch is on, users will not be able to manually register for this account.'
          )}
        </FormHelperText>
      </FormControl>
    </Container>
  );
};

export default CompanyAccessSettings;
