import { axiosPost } from '../client/axios';
import {
  DEAL_TYPE_ELA,
  GROUP,
  UNLIMITED,
  UNLIMITED_NUM,
} from '../constants/hapo.constants';
import {
  HAPO_ADD_1ST_LEVEL_LICENSE,
  HAPO_ADD_2ND_LEVEL_LICENSE,
  HAPO_CHANGE_CATEGORY,
  HAPO_CHANGE_ONPREM_SELECTION,
  HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
  HAPO_REMOVE_1ST_LEVEL_LICENSE,
  HAPO_REMOVE_2ND_LEVEL_LICENSE,
  HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION,
  HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../constants/hapoActions.constants';
import { HAPO_CART_VALIDATION_URL } from '../constants/network.constants';
import RequestForQuote, {
  ANNOUNCEMENT_CONFIG_PRODUCT_CODES,
  AnnouncementConfig,
  ProductTypeToAPIType,
} from '../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../interfaces/contextStore';
import type { IHAPODataConfig } from '../interfaces/hapo.interface';
import { groupArrayOfObjects } from './array.utils';

/***
 * *******Begin Actions **************
 */

/**
 * Action Method - Adds a license to cart
 * - If selected an orchestrator, ref last_selected_orchestrator's nested/2nd level products
 * - Else place on the 1st level
 */
export const handleAddLicenseToCart = (
  license: {
    _id: string;
    sku_name: string;
    [key: string]: any;
    min_quantity: number;
    product_code: string;
  },
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'],
  ignoreConfirmationModal: boolean = false
) => {
  const {
    _id, sku_name, min_quantity, product_code,
  } = license;
  const minQuantity = min_quantity ? min_quantity : 1;

  // If specific SKU then do something else
  if (
    !ignoreConfirmationModal &&
    ANNOUNCEMENT_CONFIG_PRODUCT_CODES.includes(product_code)
  ) {
    dispatch({
      type: HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
      payload: {
        message: AnnouncementConfig[product_code].message,
        action: 'add_product',
        sku_id: _id,
        sku_name,
        min_quantity: minQuantity,
        product_code,
      },
    });
    return;
  }

  if (is2ndLevelLicense(productType) && lastSelectedOrchestrator) {
    dispatch({
      type: HAPO_ADD_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: _id,
        sku_name,
        product_code,
        quantity: minQuantity,
      },
    });
  } else {
    dispatch({
      type: HAPO_ADD_1ST_LEVEL_LICENSE,
      payload: {
        type: ProductTypeToAPIType.get(productType),
        sku_id: _id,
        sku_name,
        product_code,
        quantity: minQuantity,
      },
    });
  }
};

const is2ndLevelLicense = (productType: string) => (
  productType === RequestForQuote.productTypes.onPrem ||
    productType === RequestForQuote.productTypes.cloud ||
    productType === RequestForQuote.productTypes.processMining ||
    productType === RequestForQuote.productTypes.automationSuite
);

/**
 * Action Method - Updates quantity of license in cart
 * - If selected an orchestrator, use last_selected_orchestrator's nested/2nd level products
 * - Else use whatever is on the 1st level
 */
export const handleQuantityUpdate = (
  licenseId: string,
  newQuantity: number,
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
) => {
  if (is2ndLevelLicense(productType) && lastSelectedOrchestrator) {
    dispatch({
      type: HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: licenseId,
        quantity: newQuantity,
      },
    });
  } else {
    dispatch({
      type: HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
      payload: {
        sku_id: licenseId,
        quantity: newQuantity,
      },
    });
  }
};

/**
 * Action Method - This handles adding initial item AND removing of item
 * Using context of where the user is, determine where to add
 *  */
export const handleRemoveLicenseFromCart = (
  licenseId: string,
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
) => {
  if (is2ndLevelLicense(productType) && lastSelectedOrchestrator) {
    dispatch({
      type: HAPO_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: licenseId,
      },
    });
  } else {
    dispatch({
      type: HAPO_REMOVE_1ST_LEVEL_LICENSE,
      payload: { sku_id: licenseId },
    });
  }
};

/**
 * Action Method for switching header product options
 */
export const handleProductTypeChange = (dispatch: any, productType: string) => {
  dispatch({
    type: HAPO_CHANGE_CATEGORY,
    payload: productType,
  });
};

export const handleOnPremOrchestratorChange = (
  dispatch: any,
  orchestratorType: string
) => {
  dispatch({
    type: HAPO_CHANGE_ONPREM_SELECTION,
    payload: orchestratorType,
  });
};

export const handleAddOrchestratorToCart = (
  dispatch: any,
  skuName: string,
  skuId: string,
  productCode: string,
  bundleType: string
) => {
  dispatch({
    type: HAPO_ADD_1ST_LEVEL_LICENSE,
    payload: {
      type: bundleType,
      sku_name: skuName,
      sku_id: skuId,
      product_code: productCode,
      quantity: 1,
    },
  });
};

export const handleResetNewOrchestrator = (dispatch: any, orchId?: string | undefined) => {
  dispatch({
    type: HAPO_REMOVE_1ST_LEVEL_LICENSE,
    payload: { _id: orchId },
  });
};

export const handleSelectExistingOrchestratorDropdown = (
  dispatch: any,
  name: string,
  license: string,
  bundleType: string,
  displayName: string
) => {
  dispatch({
    type: HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION,
    payload: {
      type: bundleType,
      existing_license_code: license,
      existing_license_name: name,
      existing_license_displayName: displayName,
    },
  });
};

/***
 * *******End of Actions **************
 */

/** *********************
 * Validations
 * *********************
 */

/**
 * Returns an object of Key = Product Code, Value = Quantity in cart
 * @param cartProducts hapoProducts
 */
const getUsedProductCodesTotals = (cartProducts: GlobalStoreState['hapoProducts']) => {
  const productCodeRunningTotal: { [productCode: string]: number } = {};

  // Loop through to get totals of product codes
  for (const {
    quantity: cartSkuQuantity,
    product_code: cartSkuProductCode,
    products: cartSkuChildProducts = [],
  } of cartProducts) {
    productCodeRunningTotal[cartSkuProductCode] =
      (productCodeRunningTotal[cartSkuProductCode] ?? 0) + cartSkuQuantity;

    for (const {
      quantity: cartSkuChildQuantity,
      product_code: cartSkuChildProductCode,
    } of cartSkuChildProducts) {
      productCodeRunningTotal[cartSkuChildProductCode] =
        (productCodeRunningTotal[cartSkuChildProductCode] ?? 0) +
        cartSkuChildQuantity;
    }
  }

  return productCodeRunningTotal;
};

export const getELAWarningInfo = (
  cartProducts: GlobalStoreState['hapoProducts'],
  hapoConfig: IHAPODataConfig,
  dealType: string
) => {
  if (dealType !== DEAL_TYPE_ELA) {
    return undefined;
  }

  const groupedSkus = hapoConfig.groups;

  if (!groupedSkus) {
    return undefined;
  }

  const productCodeRunningTotal: {
    [productCode: string]: number;
  } = getUsedProductCodesTotals(cartProducts);
  const groupsRunningTotal: { [groupName: string]: number } = {};
  const aboveCapGroupNames = new Set<string>();

  // Search into the groups to see if above qty
  for (const [ groupName, groupObj ] of Object.entries(groupedSkus)) {
    for (const sku of groupObj.skus) {
      const cartProductQty = productCodeRunningTotal[sku.product_code];
      if (cartProductQty) {
        groupsRunningTotal[groupName] =
          (groupsRunningTotal[groupName] ?? 0) + cartProductQty;

        // Consumed so far = previous orders + cart
        const consumedSoFarQty =
          (groupObj.consumed_quantity ?? 0) + groupsRunningTotal[groupName];

        const elaAboveCapQty = groupObj.ela_above_cap_quantity ?? 0;

        // If running total goes above the cap
        if (consumedSoFarQty > elaAboveCapQty) {
          aboveCapGroupNames.add(groupName);
        }
      }
    }
  }

  return { aboveCapGroups: Array.from(aboveCapGroupNames) };
};

/**
 *  checks if we have invalid SKUs in the cart
 */
export const getInvalidSKUsFromCart = async (
  cartProducts: GlobalStoreState['hapoProducts'],
  companyId: GlobalStoreState['companyId'],
  accessToken: string,
): Promise<any[]> => {

  try {
    const licenseAndCatalog = await axiosPost(
      HAPO_CART_VALIDATION_URL,
      companyId,
      accessToken,
      { cartData: cartProducts }
    );

    if (!licenseAndCatalog.data?.valid) {
      return licenseAndCatalog.data?.invalidProducts;
    }
  } catch (error) {
    console.error('Error validating cart', { cartProducts }, error);
  }

  return [];
};

/**
 *  check if we have invalid quantites of SKUs in the cart
 */
export const isCartInvalidAgainstConfig = (
  cartProducts: GlobalStoreState['hapoProducts'],
  hapoConfig: IHAPODataConfig,
  dealType: string
) => {
  // If ELA, quantities don't matter
  if (dealType === DEAL_TYPE_ELA) {
    return false;
  }
  const individualSkus = hapoConfig?.individual_skus;
  const groupedSkus = hapoConfig?.groups;

  const groupsRunningTotal: { [groupName: string]: number } = {};
  const individualRunningTotal: { [productCode: string]: number } = {};

  const getUsedTotals = (cartSkuQuantity: any, cartSkuProductCode: string) => {
    if (groupedSkus) {
      for (const groupName of Object.keys(groupedSkus)) {
        for (const groupSkuChild of groupedSkus[groupName].skus) {
          if (
            cartSkuQuantity &&
            groupSkuChild.product_code === cartSkuProductCode
          ) {
            groupsRunningTotal[groupName] =
              (groupsRunningTotal[groupName] ?? 0) + cartSkuQuantity;

            // Early return since we know at least one is bad sku
            if (
              groupedSkus[groupName].available_quantity < UNLIMITED_NUM &&
              groupsRunningTotal[groupName] >
                groupedSkus[groupName].available_quantity
            ) {
              return true;
            }
          }
        }
      }
    }

    // Loop through the children
    if (individualSkus) {
      for (const configIndivProductCode of Object.keys(individualSkus)) {
        if (configIndivProductCode === cartSkuProductCode) {
          individualRunningTotal[cartSkuProductCode] =
            (individualRunningTotal[cartSkuProductCode] ?? 0) + cartSkuQuantity;

          if (
            individualSkus[cartSkuProductCode].available_quantity <
              UNLIMITED_NUM &&
            individualRunningTotal[cartSkuProductCode] >
              individualSkus[cartSkuProductCode].available_quantity
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  for (const {
    quantity: cartSkuQuantity,
    product_code: cartSkuProductCode,
    products: cartSkuChildProducts,
  } of cartProducts) {
    if (getUsedTotals(cartSkuQuantity, cartSkuProductCode)) {
      return true;
    }

    if (
      Array.isArray(cartSkuChildProducts) &&
      cartSkuChildProducts.length > 0
    ) {
      for (const {
        quantity: cartSkuChildQuantity,
        product_code: cartSkuChildProductCode,
      } of cartSkuChildProducts) {
        if (getUsedTotals(cartSkuChildQuantity, cartSkuChildProductCode)) {
          return true;
        }
      }
    }
  }

  return false;
};

// TODO: Refactor to be used also in OrderBundle compenent, and prevent code duplication
export const getLicenseAvailableQuantity = (
  productCode: string,
  hapoConfig: IHAPODataConfig
) => {
  const individualSkus = hapoConfig?.individual_skus;
  const groupedSkus = hapoConfig?.groups;
  // First check the groups
  if (groupedSkus) {
    for (const groupValue of Object.values(groupedSkus)) {
      for (const groupSkuChild of groupValue.skus) {
        if (groupSkuChild.product_code === productCode) {
          return groupValue.available_quantity;
        }
      }
    }
  }
  // Then check the individual skus
  if (individualSkus) {
    for (const configIndivProductCode of Object.keys(individualSkus)) {
      if (configIndivProductCode === productCode) {
        return individualSkus[configIndivProductCode].available_quantity;
      }
    }
  }

  return 0;
};

// TODO: To be replaced by getLicenseAvailableQuantity, after refactor
export const getAvailableQuatity = (
  productCode: string,
  hapoConfig: IHAPODataConfig
) => {
  const individualSKUs = hapoConfig?.individual_skus;
  const groupSKUs = hapoConfig?.groups;
  let availableQuantityIndividual: number | string = 0;
  let availableQuantityGroup: number | string = 0;

  if (individualSKUs) {
    for (const configIndivProductCode of Object.keys(individualSKUs)) {
      if (configIndivProductCode === productCode) {
        availableQuantityIndividual =
          individualSKUs[configIndivProductCode].max_allowed_quantity ===
          UNLIMITED_NUM
            ? UNLIMITED.fallbackText
            : individualSKUs[configIndivProductCode].available_quantity;
      }
    }
  }

  if (groupSKUs) {
    for (const groupValue of Object.values(groupSKUs)) {
      for (const groupSkuChild of groupValue.skus) {
        if (groupSkuChild.product_code === productCode) {
          availableQuantityGroup =
            groupValue.max_allowed_quantity === UNLIMITED_NUM
              ? UNLIMITED.fallbackText
              : groupValue.available_quantity;
        }
      }
    }
  }

  return {
    availableQuantityIndividual,
    availableQuantityGroup,
  };
};

export const groupBundlesByGroupName = (bundles: any) => {
  const groupBundles: any[] = [];

  for (const bundle of bundles) {
    if (bundle.consumedAgainst === GROUP) {
      groupBundles.push(bundle);
    }
  }

  return groupArrayOfObjects(groupBundles, 'consumedAgainstGroupName');
};

export const calculateGroupBundlesAvailableQuantities = (
  groupedBundles: any,
  groupName: string
) => {
  let availableQuantity: number | string = 0;

  for (const bundle of Object.entries(groupedBundles)) {
    if (Array.isArray(bundle[1])) {
      for (const product of bundle[1]) {
        if (product.consumedAgainstGroupName === groupName) {
          availableQuantity = product.availableQuantity;
          break;
        }
      }
    }
  }

  return availableQuantity;
};
